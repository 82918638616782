import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, H5, H6, Spinner, Btn, Image, H3 } from "../../../AbstractElements";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Media, CardFooter } from "reactstrap";
import user from "../../../assets/images/user/7.jpg";

import { Api_GetListCategory } from "../../../api/Admin/ProjectCategory";
import { Api_GetMemberName } from "../../../api/Admin/Member";
import { Api_AddCategory } from "../../../api/Admin/MemberTask";

const MemberMappingCategory = () => {
  const navigate = useNavigate();
  const { memberID } = useParams();
  const [Loading, setLoading] = useState(false);
  const [userImg, setUserImg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [obj, setObj] = useState([]);
  const [member, setMember] = useState("");
  const [objCategory, setobjCategory] = useState([]);
  const LoadData = async () => {
    const response = await Api_GetListCategory(memberID);
    setTableData(response.data.data);
    console.log("res:" + JSON.stringify(response.data.data));
    const response2 = await Api_GetMemberName(memberID);
    setMember(response2.data.data.fullName);
  };
  useEffect(() => {
    LoadData();
  }, []);

  const onChangeActive = async (key) => {
    // const check = obj.some((element) => element.CategoryID === val);
    // if (check == false) {
    //   setObj((obj) => [
    //     ...obj,
    //     {
    //       CategoryID: val,
    //     },
    //   ]);
    // } else {
    //   const newobj = obj.filter((element) => element.CategoryID !== val);
    //   setObj(newobj);
    // }
    const AllData = [...tableData];
    const DataIndex = AllData.findIndex((p) => p.id == key);
    const TempData = AllData[DataIndex];
    TempData.active = !TempData.active;
    AllData[DataIndex] = TempData;
    setTableData(AllData);
  };
  const SaveData = async () => {
    const response = await Api_AddCategory(memberID, tableData);
    if (response.data.isSuccess && response.data.isSuccess == true) {
      toast.success("Saved");
      navigate(`${process.env.PUBLIC_URL}/admin/Members`);
    }
    console.log(response.data.isSuccess);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="edit-profile">
          <Breadcrumbs parent="Portal" title="Member Mapping Project Category" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Form className="theme-form">
                      <Row className="mb-2">
                        <div className="profile-title">
                          <Media>
                            {userImg == "" ? (
                              <Image
                                attrImage={{
                                  className: "img-70 rounded-circle",
                                  alt: "",
                                  src: `${user}`,
                                }}
                              />
                            ) : (
                              <Image
                                attrImage={{
                                  className: "img-70 rounded-circle",
                                  alt: "",
                                  src: userImg,
                                }}
                              />
                            )}
                            <Media body>
                              <H3 attrH3={{ className: "mb-1 f-20 txt-primary" }}>
                                <Link to={`${process.env.PUBLIC_URL}/admin/Member/edit/${memberID}`}>{member}</Link>
                              </H3>
                            </Media>
                          </Media>
                        </div>
                      </Row>
                      <Row>
                        <Col sm="12">
                          {tableData != "" ? (
                            <div className="table-responsive">
                              <Table>
                                <thead className="bg-primary">
                                  <tr>
                                    <th scope="col">{"Task"}</th>
                                    <th scope="col">{"Access"}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableData.map((item) => (
                                    <tr key={item.id}>
                                      <td>{item.title}</td>
                                      <td>
                                        <FormGroup className="form-group">
                                          <div className="checkbox">
                                            <Input id={"checkbox" + item.id} type="checkbox" defaultChecked={item.active} onClick={() => onChangeActive(item.id)} />
                                            <Label className="text-muted" for={"checkbox" + item.id}></Label>
                                          </div>
                                        </FormGroup>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </Form>
                  </Fragment>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col sm="12">
                      {tableData != "" ? (
                        <Button className="btn btn-success btn-md" onClick={() => SaveData()}>
                          Save
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default MemberMappingCategory;
