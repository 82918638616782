import SiteInformation from "../Component/admin/SiteInformation/SiteInformation";
import { ListMembers, CreateMember, EditMember, ChangePassword, MemberMappingTask, MemberMappingCategory } from "../Component/admin/Members";
import { ListProjectCategory, NewProjectCategory } from "../Component/admin/ProjectCategory";
import { EditProjectCategoryTask, ListProjectCategoryTask, NewProjectCategoryTask } from "../Component/admin/ProjectCategoryTask";
import { EditProjectCategoryLevel, ListProjectCategoryLevel, NewProjectCategoryLevel } from "../Component/admin/ProjectCategoryLevel";
import { EditCountry, ListCountry, NewCountry } from "../Component/admin/Country";
import { StaticTextList, StaticText } from "../Component/admin/StaticText";
import { EditUser, ListUser } from "../Component/admin/User";
import { ProjectListContain, ProjectRefer, ProjectManagment } from "../Component/admin/Projects";
import { MessageList } from "../Component/admin/Message";
import Calender from "../Pages/Calender/Index";
import Tasks from "../Pages/Tasks";
import Draggable from "../Pages/Calender/Draggable Calender";

import SupportTickit from "../Pages/Support Tickit";
import Profile from "../Component/admin/Members/Profile";
import ChatApp from "../Pages/Chat/ChatApp";
import ChatList from "../Component/Chat/ChatList";
import MailInbox from "../Pages/Email/Mail Inbox";
import KpiScreen from "../Component/admin/KPI/KpiScreen";
import BIScreen from "../Component/admin/BI/BIScreen";
import { ToDoListContain } from "../Component/admin/TODO";
export const AdminRoutes = [
  {
    path: `${process.env.PUBLIC_URL}/admin/SiteSetting`,
    Component: <SiteInformation />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Members`,
    Component: <ListMembers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Member/add`,
    Component: <CreateMember />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Member/edit/:memberID`,
    Component: <EditMember />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/MemberMappingTask/:memberID`,
    Component: <MemberMappingTask />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/MemberMappingCategory/:memberID`,
    Component: <MemberMappingCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Messages`,
    Component: <MessageList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Recruiters`,
    Component: <ListUser />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Recruiter/edit/:RecruiterID`,
    Component: <EditUser />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ChangePassword`,
    Component: <ChangePassword />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/project/projectlist/:FilterMod`,
    Component: <ProjectListContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/project/Refer/:ProjectID`,
    Component: <ProjectRefer />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/project/Management/:ProjectID`,
    Component: <ProjectManagment />,
  },

  {
    path: `${process.env.PUBLIC_URL}/admin/Country/Add`,
    Component: <NewCountry />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/StaticTextList`,
    Component: <StaticTextList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/StaticTextList/edit/:PostID`,
    Component: <StaticText />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Countries`,
    Component: <ListCountry />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/Country/edit/:CountryID`,
    Component: <EditCountry />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategory/Add`,
    Component: <NewProjectCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategory`,
    Component: <ListProjectCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategory/edit/:ProjectCategoryID`,
    Component: <NewProjectCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategoryTask/:ProjectCategoryID/Add`,
    Component: <NewProjectCategoryTask />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategoryTask/:ProjectCategoryID`,
    Component: <ListProjectCategoryTask />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategoryTask/:ProjectCategoryID/edit/:ProjectCategoryTaskID`,
    Component: <EditProjectCategoryTask />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategoryLevel/:ProjectCategoryID/Add`,
    Component: <NewProjectCategoryLevel />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategoryLevel/:ProjectCategoryID`,
    Component: <ListProjectCategoryLevel />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin/ProjectCategoryLevel/:ProjectCategoryID/edit/:ProjectCategoryLevelID`,
    Component: <EditProjectCategoryLevel />,
  },

  { path: `${process.env.PUBLIC_URL}/admin/todo/:FilterMod`, Component: <ToDoListContain /> },

  { path: `${process.env.PUBLIC_URL}/admin/chat/:ChatBy/:ProjectID`, Component: <ChatApp /> },
  { path: `${process.env.PUBLIC_URL}/admin/chat/:ChatBy/:ProjectID/:TaskID`, Component: <ChatApp /> },
  { path: `${process.env.PUBLIC_URL}/admin/chat/:ChatBy/:ProjectID/:TaskID/:MainID`, Component: <ChatApp /> },
  { path: `${process.env.PUBLIC_URL}/admin/ChatList/:ChatBy/`, Component: <ChatList /> },

  { path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`, Component: <Calender /> },
  { path: `${process.env.PUBLIC_URL}/tasks`, Component: <Tasks /> },
  { path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`, Component: <Draggable /> },

  {
    path: `${process.env.PUBLIC_URL}/support-ticket`,
    Component: <SupportTickit />,
  },
  { path: `${process.env.PUBLIC_URL}/admin/profile`, Component: <Profile /> },

  { path: `${process.env.PUBLIC_URL}/email/mailbox`, Component: <MailInbox /> },
  { path: `${process.env.PUBLIC_URL}/kpi`, Component: <KpiScreen /> },
  { path: `${process.env.PUBLIC_URL}/BI`, Component: <BIScreen /> },
];
