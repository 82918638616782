import axios from "axios";
import { URL, Version } from "../URL";

const Api_AddTask = async (id, categoryid, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/MemberTask?id=${id}&categoryid=${categoryid}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddCategory = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/MemberProjectCategory?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_MemberAccessToCategory_Parent = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/MemberProjectCategory/GetAllMemberAccessToCategory_Parent`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

export { Api_AddTask, Api_AddCategory, Api_MemberAccessToCategory_Parent };
