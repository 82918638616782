import React, { Fragment, useContext, useEffect } from "react";
import {
  Col,
  Form,
  Label,
  Row,
  FormGroup,
  CardBody,
  CardFooter,
  Input,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Api_List as Api_LevelList, Api_GetTaskForNewProject, Api_GetPriceLevel } from "../../../api/Admin/CategoryLevel";
import { Btn, Spinner } from "../../../AbstractElements";
import {
  Add,
  Cancel,
  ClientName,
  EndingDate,
  EnterSomeDetails,
  Priority,
  ProjectRate,
  ProjectSize,
  ProjectTitle,
  ProjectType,
  StartingDate,
} from "../../../Constant";
import DatePicker from "react-datepicker";
import { Api_List, Api_ListParent } from "../../../api/Admin/ProjectCategory";
import DeniReactTreeView from "deni-react-treeview";
import { Api_Add } from "../../../api/Admin/Project";
import { toast } from "react-toastify";
import useState from "react-usestateref";
import { format } from "date-fns";
const MainList = () => {
  const { ProjectID } = useParams();
  const history = useNavigate();
  const navigate = useNavigate();
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [CategoryList, setCategoryList] = useState([]);
  const [PriceLevel, setPriceLevel, PriceLevelRef] = useState(0);
  const [TotalPrice, setTotalPrice] = useState(0);
  const [LevelList, setLevelList] = useState([]);
  const [TaskList, setTaskList] = useState([]);
  const [modalCategory, setmodalCategory] = useState(false);
  const [ProjectCategoryList, setProjectCategoryList] = useState([]);
  const [categoryName, setcategoryName] = useState("");
  const [obj, setObj, objRef] = useState({
    title: "",
    categoryId: 0,
    levelID: 0,
    description: "",
    startingDate: new Date(),
    endingDate: new Date(),
    TaskList: [],
  });
  const themes = ["classic"];
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const onObjChange = async (event) => {
    setObj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const onSelectItemProjectCategory = async (item) => {
    try {
      setcategoryName(item.text);
      setObj({
        ...obj,
        categoryID: item.id,
      });
      setmodalCategory(false);
      LoadLevelList(item.id);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onChangeLevel = async (val) => {
    if (val == 0) {
      setTaskList(null);
      setPriceLevel(0);
      setObj({
        ...obj,
        levelID: 0,
      });
    } else {
      LoadLevelTask(val);
      setObj({
        ...obj,
        levelID: val,
      });
      var response = await Api_GetPriceLevel(val);
      console.log("price:" + response.data.price);
      setPriceLevel(response.data.price);
      handleCalculatePrice(response.data.price);
    }
  };
  const LoadLevelTask = async (val) => {
    var response = await Api_GetTaskForNewProject(val);

    setTaskList(response.data.data);
  };
  const LoadLevelList = async (val) => {
    var response = await Api_LevelList(val);
    setLevelList(response.data.data);
  };
  const handleStartDate = (date) => {
    setObj({
      ...obj,
      startingDate: date,
    });
    handleCalculatePrice(PriceLevel);
  };
  const handleEndDate = (date) => {
    setObj({
      ...obj,
      endingDate: date,
    });

    handleCalculatePrice(PriceLevel);
  };
  const handleCalculatePrice = (price) => {
    try {
      var { current } = objRef;
      // console.log("current:" + JSON.stringify(current));
      if (price > 0 && current.startingDate != "" && current.endingDate != "") {
        let difference = current.endingDate.getTime() - current.startingDate.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24)) + 1;

        if (TotalDays < 1) {
          toast.error("Invalid Ending  Date");
          setTotalPrice(0);
          //console.log("TotalDays:" + TotalDays);
          setObj({
            ...obj,
            endingDate: "",
          });
        } else {
          let _price = TotalDays * price;
          setTotalPrice(_price);
        }
      } else {
        setTotalPrice(0);
        //console.log("a");
      }
    } catch (error) {
      setTotalPrice(0);
      //console.log("b");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await Api_ListParent();

        setCategoryList(response.data);
      } catch (err) {}
    };

    fetchData();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onChangeTask = async (id, name, val) => {
    const AllData = [...TaskList];
    const DataIndex = AllData.findIndex((p) => p.taskID == id);
    const TempData = AllData[DataIndex];
    if (name == "checked") {
      TempData.checked = val;
    }
    AllData[DataIndex] = TempData;
    setTaskList(AllData);
    setObj({
      ...obj,
      TaskList: AllData,
    });
  };

  const onSubmit = async () => {
    setLoadingBtn(true);
    const response = await Api_Add(obj);

    setLoadingBtn(false);
    if (response.data) {
      if (response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else {
        if (response.data.isSuccess == true) {
          toast.success("A new Projet was created");
          navigate(`${process.env.PUBLIC_URL}/project/projectlist/Notyetstarted`);
        } else {
          toast.error("Error");
        }
      }
    } else {
      toast.error("Error");
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <CardBody>
          <Row>
            <Col sm="12" md="12">
              <FormGroup>
                <Label className="form-label">{"Project Title"}</Label>
                <label className="txt-danger Required">{"*"}</label>
                <InputGroup>
                  <Input
                    name="title"
                    maxLength={255}
                    className="form-control"
                    value={obj.title || ""}
                    onChange={onObjChange}
                    required={true}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="exampleFormControlSelect9">{"Project Category"}</Label>

                {CategoryList != null && CategoryList != "" ? (
                  <Input
                    className="form-control"
                    value={categoryName}
                    onClick={() => {
                      setmodalCategory(true);
                    }}
                    readOnly={true}
                  />
                ) : (
                  <Input
                    className="form-control"
                    value={""}
                    onClick={() => {
                      toast.info("List Is Empty");
                    }}
                    readOnly={true}
                  />
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="form-label">{"Expert Level"}</Label>
                <label className="txt-danger Required">{"*"}</label>
                <select
                  name="levelID"
                  type="select"
                  onChange={(txt) => {
                    onChangeLevel(txt.target.value);
                  }}
                  className="form-control form-select"
                >
                  <option value="" key={0}>
                    Select...
                  </option>
                  {LevelList.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    );
                  })}
                </select>
                <span style={{ color: "red" }}>{errors.categoryId && "Project Category is required"} </span>
              </FormGroup>
            </Col>
            <Col sm="12">
              {TaskList != null && TaskList != "" ? (
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="bg-primary">
                        <tr>
                          <th scope="col" colSpan={2}>
                            Select Tasks Need
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {TaskList.map((item) => {
                          return (
                            <tr key={item.id}>
                              <th scope="row">{item.title}</th>
                              <td>
                                <FormGroup className="form-group">
                                  <div className="checkbox">
                                    <Input
                                      id={item.taskID + "checkboxSee"}
                                      type="checkbox"
                                      defaultChecked={item.checked}
                                      onClick={() => onChangeTask(item.taskID, "checked", !item.checked)}
                                    />
                                    <Label className="text-muted" for={item.taskID + "checkboxSee"}></Label>
                                  </div>
                                </FormGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
            </Col>
            <Col sm="12" md="4">
              <FormGroup>
                <Label className="form-label">{"Starting Date"}</Label>
                <label className="txt-danger Required">{"*"}</label>
                <DatePicker className="datepicker-here form-control" selected={obj.startingDate} onChange={handleStartDate} />
              </FormGroup>
            </Col>
            <Col sm="12" md="4">
              <FormGroup>
                <Label className="form-label">{"Ending Date"}</Label>
                <label className="txt-danger Required">{"*"}</label>
                <DatePicker className="datepicker-here form-control" selected={obj.endingDate} endDate={obj.endingDate} onChange={handleEndDate} />
              </FormGroup>
            </Col>
            <Col sm="12" md="4">
              <FormGroup>
                <Label className="form-label">{"Cost Estimation"}</Label>
                <Input className="form-control" value={TotalPrice || ""} onChange={onObjChange} autoComplete="off" />
              </FormGroup>
            </Col>
            <Col md="12">
              <div>
                <Label className="form-label">{"Description"}</Label>
                <textarea
                  className="form-control"
                  rows="5"
                  name="description"
                  placeholder="Enter About your Project description"
                  onChange={onObjChange}
                ></textarea>
              </div>
              <span style={{ color: "red" }}>{errors.description && "Description is required"} </span>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Row>
            <Col sm="12">
              <Button className="btn btn-success btn-md m-r-15">
                {LoadingBtn ? (
                  <div className="loader-box2">
                    <Spinner attrSpinner={{ className: "loader-7" }} />
                  </div>
                ) : ProjectID ? (
                  "Save"
                ) : (
                  "Add"
                )}
              </Button>
              <Btn
                attrBtn={{
                  color: "light",
                  type: "button",
                  onClick: () => navigate(`${process.env.PUBLIC_URL}/project/projectlist`),
                }}
              >
                Cancel
              </Btn>
            </Col>
          </Row>
        </CardFooter>
      </Form>
      <Modal isOpen={modalCategory}>
        <ModalHeader>Parent</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemProjectCategory}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={CategoryList}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setcategoryName("");
              setmodalCategory(false);
              setLevelList(null);
              setObj({
                ...obj,
                categoryID: 0,
              });
            }}
          >
            Clean Select
          </Button>
          <Button color="light" onClick={() => setmodalCategory(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default MainList;
