import React from "react";
import { Radar } from "react-chartjs-2";
import { Col, Card, CardBody, CardHeader } from "reactstrap";

import { RadarChartData } from "../../../../Data/ChartJsData";
import { H5 } from "../../../../AbstractElements";

const RadarChartClasstwo = () => {
  return (
    <Col xl="12" md="12" className="box-col-12">
      <Card>
        <CardHeader className="pb-0">
          <H5>performance Chart</H5>
        </CardHeader>
        <CardBody className="radar-chartjs">
          <Radar id="myRadarGraph" data={RadarChartData} width="600px" height="300px" />
        </CardBody>
      </Card>
    </Col>
  );
};

export default RadarChartClasstwo;
