import React, { Fragment, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs, P, H6 } from "../../../../AbstractElements";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Media,
  CardFooter,
  Progress,
} from "reactstrap";
import {
  Api_ChangeStatusProject,
  Api_ListExpert,
  Api_DoingTaskRefer,
  Api_ExpertAddTaskProject,
  Api_ListReferProject,
  UpdateEnableTaskRefer,
  Api_UpdateEnableTaskRefer,
  Api_ProjectInfo,
} from "../../../../api/Admin/Project";
import { toast } from "react-toastify";
import MemberTypeContext from "../../../../_helper/MemberType";
import { Link, useNavigate } from "react-router-dom";
const ProjectManagment = (props) => {
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [objProject, setobjProject] = useState([]);
  const { ProjectID } = useParams();
  const LoadData = async () => {
    const response = await Api_ListExpert(ProjectID);
    LoadDataRefer();
    ProjectInfo();
    console.log("LoadData:" + JSON.stringify(response.data.data));
  };
  useEffect(() => {
    LoadData();
  }, []);

  const LoadDataRefer = async () => {
    const response = await Api_ListReferProject(ProjectID, "0");
    setTableData2(response.data.data);
    console.log("Api_ListReferProject:" + JSON.stringify(response.data.data));
  };

  const ProjectInfo = async () => {
    const response = await Api_ProjectInfo(ProjectID);
    setobjProject(response.data.data);
    console.log(JSON.stringify(response));
  };

  const onChangeStatusProject = async (val) => {
    const response = await Api_ChangeStatusProject(ProjectID, val);
    console.log(response);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Project" title="Project Managment" />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Fragment>
                  <Row>
                    <Col md="12">
                      <div className="project-box">
                        <H6>
                          <span>Recruiter Name:</span>
                          {objProject.authorFirstName} {objProject.authorLastName}
                        </H6>
                        <span
                          className={`badge ${
                            objProject.projectStatus === "Done"
                              ? "badge-success"
                              : objProject.projectStatus === "Cancel"
                              ? "badge-danger"
                              : objProject.projectStatus === "Not yet started"
                              ? "badge-warning"
                              : objProject.projectStatus === "Notyetstarted"
                              ? "badge-warning"
                              : "badge-primary"
                          }`}
                        >
                          {objProject.projectStatus}
                        </span>
                        <H6>{objProject.title}</H6>
                        <Media>
                          <Media body>
                            <P>{objProject.categoryTitle}</P>
                          </Media>
                        </Media>
                        <P>{objProject.description}</P>
                        <Row className="details">
                          <Col xs="3">
                            <span>From Date</span>
                          </Col>
                          <Col xs="3" className="font-secondary">
                            {objProject.startingDate}
                          </Col>
                          <Col xs="3">
                            <span>To Date</span>
                          </Col>
                          <Col xs="3" className="font-secondary">
                            {objProject.endingDate}
                          </Col>
                          <Col xs="3">
                            <span>Budget</span>
                          </Col>
                          <Col xs="3" className="font-secondary">
                            £{objProject.budget}
                          </Col>
                        </Row>

                        <div className="project-status mt-4">
                          <Media className="mb-0">
                            <P>{objProject.progress}% </P>
                            <Media body className="text-end">
                              <span>Done</span>
                            </Media>
                          </Media>
                          {objProject.progress == 100 ? (
                            <Progress className="sm-progress-bar" color="success" value={objProject.progress} style={{ height: "5px" }} />
                          ) : objProject.progress > 0 && objProject.progress < 100 ? (
                            <Progress className="sm-progress-bar" striped color="primary" value={objProject.progress} style={{ height: "5px" }} />
                          ) : (
                            <Progress className="sm-progress-bar" striped color="warning" value={objProject.progress} style={{ height: "5px" }} />
                          )}
                        </div>
                      </div>
                    </Col>
                    {typeName == "ProjectManager" ? (
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">{"Change Status"}</Label>
                          {objProject != "" ? (
                            <Input
                              type="select"
                              name="select"
                              className="form-control digits"
                              defaultValue={objProject.projectStatus}
                              onChange={(txt) => {
                                onChangeStatusProject(txt.target.value);
                              }}
                            >
                              <option value="Notyetstarted" key="Notyetstarted">
                                Not yet started
                              </option>
                              <option value="Diong" key="Diong">
                                DOING
                              </option>
                              <option key={"Delivered"} value={"Delivered"}>
                                Delivered
                              </option>
                              <option value="Done" key="Done">
                                Done
                              </option>
                              <option value="Cancel" key="Cancel">
                                Cancel
                              </option>
                            </Input>
                          ) : null}
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {tableData2 != "" ? (
        <Container fluid={true}>
          <Col sm="12">
            <Card>
              <CardBody>
                <Fragment>
                  <Row>
                    <Label htmlFor="exampleFormControlSelect9">{"Experts"}</Label>
                    <Col sm="12">
                      <div className="table-responsive">
                        <Table>
                          <thead className="bg-primary">
                            <tr>
                              <th scope="col">{"Task"}</th>
                              <th scope="col">{"Progress"}</th>
                              <th scope="col">{"Chat"}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData2.map((item) => (
                              <tr key={item.id}>
                                <td>{item.projectCategoryTaskTitle}</td>

                                <td>
                                  <div className="project-status">
                                    <Media className="mb-0">
                                      <P>{item.progress}% </P>
                                      <Media body className="text-end">
                                        <span>Done</span>
                                      </Media>
                                    </Media>
                                    {item.progress == 100 ? (
                                      <Progress className="sm-progress-bar" color="success" value={item.progress} style={{ height: "5px" }} />
                                    ) : (
                                      <Progress className="sm-progress-bar" striped color="primary" value={item.progress} style={{ height: "5px" }} />
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <Button
                                    className="btn btn-success mr-5 btn-xs"
                                    onClick={() => navigate(`${process.env.PUBLIC_URL}/chat/Administrator/${item.projectID}/${item.taskID}`)}
                                  >
                                    Chat by Admin
                                  </Button>
                                  <Button
                                    className="btn btn-success mr-5 btn-xs"
                                    onClick={() => navigate(`${process.env.PUBLIC_URL}/chat/Expert/${item.projectID}/${item.taskID}`)}
                                  >
                                    Chat by Expert
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Container>
      ) : null}
    </Fragment>
  );
};
export default ProjectManagment;
