import React, { useEffect, useState, Fragment } from "react";
import { Api_MembersList, Api_DeleteMember } from "../../../api/Admin/Member";
import { Btn, H6, Image, H5 } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Table } from "reactstrap";

const ListMembers = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [SelectedMemberID, setSelectedMemberID] = useState(0);
  const [modal, setModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const LoadData = async () => {
    try {
      setLoading(true);
      const response = await Api_MembersList();
      setTableData(response.data);
      setLoading(false);
    } catch (error) {
      toast.error("error");
    }
  };
  useEffect(() => {
    LoadData();
  }, []);
  const DeleteMember = async (SelectedMemberID) => {
    try {
      setLoading(true);
      const { data: response, status } = await Api_DeleteMember(SelectedMemberID);
      if (status == 200 && response.isSuccess == true) {
        toast.success("Member  Deleted");
        removeFromList(SelectedMemberID);
      } else if (status == 200 && response.isSuccess == false) {
        toast.error(response.Message);
      } else {
        toast.error("Error");
      }
      setModal(false);
      setLoading(false);
    } catch (error) {
      toast.error("error");
      setLoading(false);
    }
  };
  const removeFromList = async (SelectedMemberID) => {
    try {
      setLoading(true);
      const updatedContact = tableData.filter((c) => c.id !== SelectedMemberID);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
      setLoading(false);
    }
  };
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Members" title="List Members" Loading={Loading} />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {tableData ? (
                  <div className="table-responsive">
                    <Table>
                      <thead className="bg-primary">
                        <tr>
                          <th scope="col">{"First & Last Name"}</th>
                          <th scope="col">{"Member Type"}</th>
                          <th scope="col">{"Last Login"}</th>
                          <th scope="col">{"Active"}</th>
                          <th scope="col">{"Access"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item) => (
                          <tr key={item.id}>
                            <td>
                              {item.firstName} {item.lastName}
                            </td>
                            <td>{item.memberType}</td>
                            <td>{item.lastLoginDate}</td>

                            {item.isActive ? <td className="txt-success">Active</td> : <td className="txt-danger">deActive</td>}
                            <td>
                              {item.memberType == "Expert" ? (
                                <span>
                                  <Button className="btn btn-success mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/MemberMappingTask/${item.id}`)}>
                                    Tasks
                                  </Button>
                                </span>
                              ) : null}
                              {item.memberType == "ProjectManager" ? (
                                <span>
                                  <Button className="btn btn-success mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/MemberMappingCategory/${item.id}`)}>
                                    Category
                                  </Button>
                                </span>
                              ) : null}
                            </td>
                            <td>
                              <>
                                <span>
                                  <Button className="btn btn-info mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/Member/edit/${item.id}`)}>
                                    Edit
                                  </Button>
                                </span>
                                <span>
                                  <Button
                                    className="btn btn-danger btn-xs"
                                    onClick={() => {
                                      setSelectedMemberID(item.id);
                                      toggle();
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </span>
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => DeleteMember(SelectedMemberID)}>
            Yes
          </Button>
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListMembers;
