import React, { useEffect, useState, Fragment } from "react";
import { Api_List, Api_Delete } from "../../../api/Admin/ProjectCategoryTask";
import { Api_CategoryName } from "../../../api/Admin/ProjectCategory";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn, H6, Image } from "../../../AbstractElements";

import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { CheckCircle, Info, PlusCircle, Target, SkipBack } from "react-feather";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Table } from "reactstrap";
import { H5 } from "../../../AbstractElements";
const ListProjectCategoryTask = () => {
  const { ProjectCategoryID } = useParams();
  const navigate = useNavigate();
  const [SelectetID, setSelectetID] = useState(0);
  const [CategoryName, setCategoryName] = useState("");
  const [modal, setModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const LoadData = async () => {
    console.log("1");
    const { data: response2 } = await Api_CategoryName(ProjectCategoryID);
    console.log("2");
    setCategoryName(response2.data.title);
    const response = await Api_List(ProjectCategoryID);
    console.log("3");
    setTableData(response.data.data);
    console.log("response:" + response.data.data);
  };
  useEffect(() => {
    console.log("a");
    LoadData();
  }, []);
  const DeleteMember = async (SelectetID) => {
    const { data: response, status } = await Api_Delete(SelectetID);
    if (status == 200 && response.isSuccess == true) {
      toast.success("Task  Deleted");
      removeFromList(SelectetID);
    } else if (status == 200 && response.isSuccess == false) {
      toast.error(response.Message);
    } else {
      toast.error("Error");
    }
    setModal(false);
  };
  const removeFromList = async (SelectetID) => {
    try {
      const updatedContact = tableData.filter((c) => c.id !== SelectetID);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent={"Task List"} title={CategoryName} />
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col md="12">
                  <div className="btn-showcase">
                    <Link className="btn btn-primary" style={{ color: "white" }} to={`${process.env.PUBLIC_URL}/admin/ProjectCategoryTask/${ProjectCategoryID}/Add`}>
                      <PlusCircle />
                      Create New Task
                    </Link>

                    <Link className="btn  btn-light" to={`${process.env.PUBLIC_URL}/admin/ProjectCategory`}>
                      <SkipBack />
                      Go Back To Project Category
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <CardBody>
                {tableData ? (
                  <div className="table-responsive">
                    <Table>
                      <thead className="bg-primary">
                        <tr>
                          <th scope="col">{"Name"}</th>

                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.title}</td>

                            <td>
                              <>
                                <span>
                                  <Button className="btn btn-info mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategoryTask/${ProjectCategoryID}/edit/${item.id}`)}>
                                    Edit
                                  </Button>
                                </span>
                                <span>
                                  <Button
                                    className="btn btn-danger btn-xs"
                                    onClick={() => {
                                      setSelectetID(item.id);
                                      toggle();
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </span>
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => DeleteMember(SelectetID)}>
            Yes
          </Button>
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListProjectCategoryTask;
