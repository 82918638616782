import axios from "axios";
import { URL, Version } from "../URL";

const Api_List = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Categories`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Get = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Categories/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Edit = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${URL}${Version}/Categories?id=${id}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Add = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Categories`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_Delete = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/Categories/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
export { Api_List, Api_Edit, Api_Get, Api_Delete, Api_Add };
