import React, { useEffect, Fragment, useContext } from "react";

import { Btn, H6, Image, H5 } from "../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { Api_Get } from "../../api/Admin/Chat";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import MemberTypeContext from "../../_helper/MemberType";
import useState from "react-usestateref";
const ChatList = () => {
  const { typeName } = useContext(MemberTypeContext);
  const navigate = useNavigate();
  const { ChatBy } = useParams();
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [obj, setObj, objRef] = useState({
    chatBy: ChatBy,
  });
  const LoadData = async () => {
    try {
      setLoading(true);
      const newobj = objRef.current;
      const response = await Api_Get(0, 0, newobj.chatBy);
      setTableData(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error("error");
    }
  };
  useEffect(() => {
    LoadData();
  }, []);
  const onChangeChatBy = (val) => {
    if (typeName == "Recruiter") {
      navigate(`${process.env.PUBLIC_URL}/ChatList/${val}`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/admin/ChatList/${val}`);
    }
  };
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Chat" title={"Chat List with " + obj.chatBy} Loading={Loading} />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Col sm="4">
                  <FormGroup>
                    <Label htmlFor="exampleFormControlSelect9">{"Chat with"}</Label>
                    <Input
                      type="select"
                      name="chatBy"
                      className="form-control digits"
                      value={obj.chatBy}
                      onChange={(txt) => {
                        onChangeChatBy(txt.target.value);
                      }}
                    >
                      {typeName == "Expert" ? (
                        <>
                          <option value={"Administrator"} key={"Administrator"}>
                            Admin
                          </option>
                          <option value={"Recruiter"} key={"Recruiter"}>
                            Recruiter
                          </option>
                        </>
                      ) : typeName == "Recruiter" ? (
                        <>
                          <option value={"Expert"} key={"Expert"}>
                            Expert
                          </option>
                          <option value={"Administrator"} key={"Administrator"}>
                            Admin
                          </option>
                        </>
                      ) : typeName == "Administrator" ? (
                        <>
                          <option value={"Expert"} key={"Expert"}>
                            Expert
                          </option>
                          <option value={"Recruiter"} key={"Recruiter"}>
                            Recruiter
                          </option>
                          <option value={"ProjectManager"} key={"ProjectManager"}>
                            ProjectManager
                          </option>
                        </>
                      ) : typeName == "ProjectManager" ? (
                        <>
                          <option value={"Expert"} key={"Expert"}>
                            Expert
                          </option>
                          <option value={"Administrator"} key={"Administrator"}>
                            Admin
                          </option>
                          <option value={"Recruiter"} key={"Recruiter"}>
                            Recruiter
                          </option>
                        </>
                      ) : null}
                    </Input>
                  </FormGroup>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {tableData ? (
                  <div className="table-responsive">
                    <Table>
                      <thead className="bg-primary">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                          <th scope="col">{"Sender"}</th>
                          <th scope="col">{"Member Type"}</th>
                          <th scope="col">{"Project Title"}</th>
                          <th scope="col">{"Data"}</th>
                          <th scope="col">{"Message"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <Image
                                attrImage={{
                                  src: `${item.img}`,
                                  className: `rounded-circle  chat-user-img img-30`,
                                  alt: "",
                                }}
                              />
                            </td>
                            <td>
                              <>
                                {typeName == "Recruiter" ? (
                                  <span>
                                    <Button
                                      className="btn btn-info mr-5 btn-xs"
                                      onClick={() =>
                                        item.taskID == null
                                          ? navigate(`${process.env.PUBLIC_URL}/chat/${ChatBy}/${item.projectID}`)
                                          : navigate(`${process.env.PUBLIC_URL}/chat/${ChatBy}/${item.projectID}/${item.taskID}`)
                                      }
                                    >
                                      Go To Chat
                                    </Button>
                                  </span>
                                ) : (
                                  <span>
                                    <Button
                                      className="btn btn-info mr-5 btn-xs"
                                      onClick={() =>
                                        item.taskID == null
                                          ? navigate(`${process.env.PUBLIC_URL}/admin/chat/${ChatBy}/${item.projectID}`)
                                          : navigate(`${process.env.PUBLIC_URL}/admin/chat/${ChatBy}/${item.projectID}/${item.taskID}`)
                                      }
                                    >
                                      Go To Chat
                                    </Button>
                                  </span>
                                )}
                              </>
                            </td>
                            <td>
                              {item.userFirstName} {item.userLastName}
                            </td>
                            <td>{item.userMemberType}</td>
                            <td>{item.projectTitle}</td>
                            <td>{item.dataAdd.toString().substring(0, 10) + " " + item.dataAdd.toString().substring(11, 16)}</td>
                            <td>{item.message}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ChatList;
