import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import DefaultContain from "../../../Component/DashBoard/Default/index";
import { useNavigate } from "react-router-dom";
import { Label } from "reactstrap";
import MemberTypeContext from "../../../_helper/MemberType/index";
import Select from "react-select";

const Default = (props) => {
  const navigate = useNavigate();

  const { typeName, setTypeName } = useContext(MemberTypeContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return <Fragment></Fragment>;
};
export default Default;
