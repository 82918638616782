import axios from "axios";
import { URL, Version } from "../URL";

const Api_List = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Project`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Get = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Project/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Edit = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/Project?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Add = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Project`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_Delete = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/Project/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_MyList = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/MyList`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_MyListByStatus = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/MyListByStatus/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_PMList = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/PMList`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ExpertMyProjectList = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/ExpertMyProjectList`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};

const Api_PMListByStatus = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/PMListByStatus/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ExpertMyProjectListByStatus = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/ExpertMyProjectListByStatus/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};

const Api_ListExpert = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/ListExpert/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ListExpertTaskProject = async (id, expertID) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/ListExpertTaskProject/${id}/${expertID}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ExpertAddTaskProject = async (id, expertID, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Project/ExpertAddTaskProject/${id}/${expertID}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_UpdateEnableTaskRefer = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Project/UpdateEnableTaskRefer/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ListReferProject = async (id, mod) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/ListReferProject/${id}/${mod}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};

const Api_ProjectInfo = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/ProjectInfo/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ChangeStatusProject = async (ProjectID, StatusID) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/ChangeStatusProject/${ProjectID}/${StatusID}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DoingTaskRefer = async (id, val) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/DoingTaskRefer/${id}/${val}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ListTaskFor = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/ListTaskFor/${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_SetTaskToExpert = async (projectid, taskid, expertid) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Project/SetTaskToExpert/${projectid}/${taskid}/${expertid}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetAllExpertAccessToCategory_Parent = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Project/GetAllExpertAccessToCategory_Parent`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetAllProject_Parent = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Project/GetAllProject_Parent/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetTasksByFilter = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Project/GetTasksByFilter/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ProjectList = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Project/ProjectList/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetAllRecruiterAccessToCategory_Parent = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Project/GetAllRecruiterAccessToCategory_Parent/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export {
  Api_SetTaskToExpert,
  Api_DoingTaskRefer,
  Api_List,
  Api_Edit,
  Api_Get,
  Api_Delete,
  Api_Add,
  Api_MyList,
  Api_MyListByStatus,
  Api_PMList,
  Api_PMListByStatus,
  Api_ListExpert,
  Api_ListExpertTaskProject,
  Api_ExpertAddTaskProject,
  Api_ListReferProject,
  Api_UpdateEnableTaskRefer,
  Api_ProjectInfo,
  Api_ChangeStatusProject,
  Api_ExpertMyProjectList,
  Api_ExpertMyProjectListByStatus,
  Api_ListTaskFor,
  Api_GetAllExpertAccessToCategory_Parent,
  Api_GetAllProject_Parent,
  Api_GetTasksByFilter,
  Api_ProjectList,
  Api_GetAllRecruiterAccessToCategory_Parent,
};
