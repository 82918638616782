import React, { useEffect, useState } from "react";
import Context from "./index";
import { userRole } from "../../api/Auth/GetToken";
import { toast } from "react-toastify";
const MemberTypeProvider = (props) => {
  const [typeName, setTypeName] = useState("");

  const HandelsetTypeName = (id) => {
    setTypeName(id);
  };
  const getToken = async () => {
    try {
      var token = await localStorage.getItem("token");
      if (token) {
        const response = await userRole();

        if (response == "404") {
          toast.error("Error connecting to the server");
        } else if (response.status == "200") {
          setTypeName(response.data.memberType);
          console.log("memberType:" + response.data.memberType);
        } else {
          toast.error("Error connecting to the server");
        }
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  useEffect(() => {
    if (typeName == "") {
      getToken();
    }
  }, []);
  return (
    <Context.Provider
      value={{
        ...props,
        typeName,

        setTypeName: HandelsetTypeName,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default MemberTypeProvider;
