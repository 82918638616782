import Default from "../Pages/DashBoard/Default/Default";
import CreateList from "../Pages/Project/Create List";
import ProjectList from "../Pages/Project/Project List";
import UsersProfile from "../Pages/Users/UsersProfile";
import UsersEdit from "../Pages/Users/UsersEdit";
import UsersCardss from "../Pages/Users/UsersCards";
import ChangePassword from "../Component/Users/UsersEdit/ChangePassword";
import ProjectManagment from "../Component/Project/Project List/Project Data/ProjectManagment";
import ChatApp from "../Pages/Chat/ChatApp";
import MailInbox from "../Pages/Email/Mail Inbox";
import KpiScreen from "../Component/admin/KPI/KpiScreen";
import BIScreen from "../Component/admin/BI/BIScreen";
import Calender from "../Pages/Calender/Index";
import ChatList from "../Component/Chat/ChatList";
export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Default /> },

  // {
  //   path: `${process.env.PUBLIC_URL}/user/Profile`,
  //   Component: <UserProfile />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/users/userprofile`,
    Component: <UsersProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/useredit`,
    Component: <UsersEdit />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/usercard`,
    Component: <UsersCardss />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ChangePassword`,
    Component: <ChangePassword />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/projectlist/:FilterMod`,
    Component: <ProjectList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/createlist/`,
    Component: <CreateList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/Management/:ProjectID`,
    Component: <ProjectManagment />,
  },
  { path: `${process.env.PUBLIC_URL}/chat/:ChatBy/:ProjectID`, Component: <ChatApp /> },
  { path: `${process.env.PUBLIC_URL}/chat/:ChatBy/:ProjectID/:TaskID`, Component: <ChatApp /> },
  { path: `${process.env.PUBLIC_URL}/chat/:ChatBy/:ProjectID/:TaskID/:MainID`, Component: <ChatApp /> },
  { path: `${process.env.PUBLIC_URL}/ChatList/:ChatBy/`, Component: <ChatList /> },
  { path: `${process.env.PUBLIC_URL}/email/mailbox`, Component: <MailInbox /> },
  { path: `${process.env.PUBLIC_URL}/kpi`, Component: <KpiScreen /> },
  { path: `${process.env.PUBLIC_URL}/BI`, Component: <BIScreen /> },
  { path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`, Component: <Calender /> },
];
