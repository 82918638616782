import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useForm } from "react-hook-form";
import { Api_GetMemberTypeList, Api_AddMember } from "../../../api/Admin/Member";
import { Btn, H6, Image, P, Spinner, H5 } from "../../../AbstractElements";
const CreateMember = () => {
  const navigate = useNavigate();
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Repeatpassword, setRepeatpassword] = useState("");
  const [MemberObj, setMemberObj] = useState({
    userName: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    memberType: "",
    isActive: false,
  });
  const [memberType, setMemberType] = useState([]);
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const { data: response2 } = await Api_GetMemberTypeList();
        setMemberType(response2.data);
      } catch (err) {}
      setLoading(false);
    };

    fetchData();
  }, []);
  const onMemberChange = (event) => {
    setMemberObj({
      ...MemberObj,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeMemberType = (val) => {
    setMemberObj({
      ...MemberObj,
      memberType: val,
    });
  };
  const onChangeActive = (val) => {
    setMemberObj({
      ...MemberObj,
      isActive: val,
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async () => {
    try {
      setLoading(true);
      setLoadingBtn(true);
      console.log(MemberObj);
      const response = await Api_AddMember(MemberObj);

      if (response.status == "200" && response.data.isSuccess == true) {
        toast.success("New " + MemberObj.memberType + " Added");
        navigate(`${process.env.PUBLIC_URL}/admin/Members`);
      } else if (response.status == "200" && response.data.isSuccess == false) {
        toast.error(response.data.message);
      } else if (response.response.status == "500") {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error");
      }
      setLoading(false);
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      setLoading(false);
    }
  };
  const onError = (errors, e) => console.log(errors, e);

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Members" title="Create Member" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Row>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"First Name"}</Label>
                          <InputGroup>
                            <Input name="firstName" className="form-control" value={MemberObj.firstName} onChange={onMemberChange} required={true} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Last Name"}</Label>
                          <InputGroup>
                            <Input name="lastName" className="form-control" value={MemberObj.lastName} onChange={onMemberChange} required={true} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"User Name"}</Label>
                          <InputGroup>
                            <Input
                              name="userName"
                              className="form-control"
                              value={MemberObj.userName || ""}
                              onChange={onMemberChange}
                              required={true}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Email"}</Label>
                          <InputGroup>
                            <Input name="email" className="form-control" value={MemberObj.email} onChange={onMemberChange} required={true} />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">{"Member Type"}</Label>
                          {memberType != "" ? (
                            <Input
                              type="select"
                              name="select"
                              className="form-control digits"
                              defaultValue={MemberObj.memberType}
                              onChange={(txt) => {
                                onChangeMemberType(txt.target.value);
                              }}
                            >
                              <option key={0}>Select...</option>
                              {memberType.map((item) => {
                                return <option key={item.id}>{item.name}</option>;
                              })}
                            </Input>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Password"}</Label>
                          <InputGroup>
                            <Input
                              name="password"
                              className="form-control"
                              value={MemberObj.password}
                              onChange={onMemberChange}
                              required={true}
                              type="password"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label>{"Repeat password"}</Label>
                          <InputGroup>
                            <Input
                              name="password"
                              className="form-control"
                              value={Repeatpassword}
                              onChange={(val) => {
                                setRepeatpassword(val.target.value);
                              }}
                              required={true}
                              type="password"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col
                        sm="4"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          alignSelf: "center",
                        }}
                      >
                        <FormGroup className="form-group">
                          <div className="checkbox">
                            <Input
                              id="checkbox1"
                              type="checkbox"
                              defaultChecked={MemberObj.isActive}
                              onClick={() => onChangeActive(!MemberObj.isActive)}
                            />
                            <Label className="text-muted" for="checkbox1">
                              Active
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        {LoadingBtn ? (
                          <Button disabled={true} className="btn btn-success btn-md">
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          </Button>
                        ) : (
                          <Button className="btn btn-success btn-md">Add</Button>
                        )}
                      </Col>
                    </Row>
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};

export default CreateMember;
