import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";

import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Api_GetMember, Api_EditMember, Api_GetMemberTypeList } from "../../../api/Admin/Member";
const EditMember = () => {
  const navigate = useNavigate();
  const { memberID } = useParams();
  const [Loading, setLoading] = useState(false);
  const [MemberObj, setMemberObj] = useState([]);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [memberType, setMemberType] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await Api_GetMember(memberID);
        setMemberObj(response.data);
        const { data: response2 } = await Api_GetMemberTypeList();
        setMemberType(response2.data);
      } catch (err) {
        console.log(err.message);
        // setLoading(false);
      }
    };

    fetchData();
  }, []);
  const onMemberChange = (event) => {
    setMemberObj({
      ...MemberObj,
      [event.target.name]: event.target.value,
    });
  };
  const SaveData = async () => {
    try {
      setLoadingBtn(true);
      const { data: response, status } = await Api_EditMember(memberID, MemberObj);
      if (status == 200 && response.isSuccess == true) {
        toast.success("Information edited");
        navigate(`${process.env.PUBLIC_URL}/admin/Members`);
      } else if (status == 200 && response.isSuccess == false) {
        toast.error(response.message);
      } else {
        toast.error("Error");
      }
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
    }
  };
  const onChangeMemberType = (val) => {
    setMemberObj({
      ...MemberObj,
      memberType: val,
    });
  };
  const handleChange = (event) => {
    setMemberObj({
      ...MemberObj,
      isActive: event.target.checked,
    });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Members" title="Edit Member" Loading={Loading} />
        <Row>
          {MemberObj != "" ? (
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Form className="theme-form">
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label>{"First Name"}</Label>
                            <InputGroup>
                              <Input name="firstName" className="form-control" value={MemberObj.firstName} onChange={onMemberChange} required={true} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>{"Last Name"}</Label>
                            <InputGroup>
                              <Input name="lastName" className="form-control" value={MemberObj.lastName} onChange={onMemberChange} required={true} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>{"User Name"}</Label>
                            <InputGroup>
                              <Input name="userName" className="form-control" value={MemberObj.userName} onChange={onMemberChange} required={true} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>{"Email"}</Label>
                            <InputGroup>
                              <Input name="email" className="form-control" value={MemberObj.email} onChange={onMemberChange} required={true} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Member Type"}</Label>
                            {memberType != "" ? (
                              <Input
                                type="select"
                                name="select"
                                className="form-control digits"
                                defaultValue={MemberObj.memberType}
                                onChange={(txt) => {
                                  onChangeMemberType(txt.target.value);
                                }}
                              >
                                {memberType.map((item) => {
                                  return <option key={item.id}>{item.name}</option>;
                                })}
                              </Input>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col
                          sm="4"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            alignSelf: "center",
                          }}
                        >
                          <FormGroup className="form-group">
                            <div className="checkbox">
                              <Input id="checkbox1" type="checkbox" checked={MemberObj.isActive} onChange={handleChange} />
                              <Label className="text-muted" for="checkbox1">
                                Active
                              </Label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Btn
                            attrBtn={{
                              color: "primary",
                              className: "m-r-15",
                              type: "button",
                              onClick: () => SaveData(),
                            }}
                          >
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Btn>
                          <Btn
                            attrBtn={{
                              color: "light",
                              type: "button",
                              onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/Members`),
                            }}
                          >
                            Cancel
                          </Btn>
                        </Col>
                      </Row>
                    </Form>
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          ) : null}
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditMember;
