import ToDoExpert from "./ToDoExpert";
import ToDoProjectManagers from "./ToDoProjectManagers";
import React, { Fragment, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import MemberTypeContext from "../../../_helper/MemberType";
import { useParams } from "react-router-dom";

const ToDoListContain = (props) => {
  const { typeName } = useContext(MemberTypeContext);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row className="project-cards">{typeName == "Expert" ? <ToDoExpert /> : <ToDoProjectManagers />}</Row>
      </Container>
    </Fragment>
  );
};
export default ToDoListContain;
