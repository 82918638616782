import React, { Fragment, useContext, useEffect, useRef, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Progress,
  Media,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import MemberTypeContext from "../../../_helper/MemberType";
import { Breadcrumbs, P } from "../../../AbstractElements";
import { toast } from "react-toastify";
import MaterialReactTable from "material-react-table";
import DeniReactTreeView from "deni-react-treeview";
import { Api_MemberAccessToCategory_Parent } from "../../../api/Admin/MemberTask";
import useState from "react-usestateref";
import { Api_GetAllExpertAccessToCategory_Parent, Api_GetAllProject_Parent, Api_GetTasksByFilter } from "../../../api/Admin/Project";

const ToDoProjectManagers = (props) => {
  const navigate = useNavigate();
  const { FilterMod } = useParams();
  const { typeName } = useContext(MemberTypeContext);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ProjectList, setProjectList] = useState([]);
  const [taskList, settaskList] = useState([]);
  const [ExpertList, setExpertList] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [expertName, setexpertName] = useState("");
  const [ProjectCategoryList, setProjectCategoryList] = useState([]);
  const [modalCategory, setmodalCategory] = useState(false);
  const [modalExpert, setmodalExpert] = useState(false);
  const [modalProject, setmodalProject] = useState(false);
  const [categoryName, setcategoryName] = useState("");
  const tableInstanceRef = useRef(null);
  const [rowSelection, setRowSelection] = useState({});
  const LoadData = async () => {
    try {
      setLoading(true);
      const response = await Api_MemberAccessToCategory_Parent();
      setProjectCategoryList(response.data.data);
      setLoading(false);
      setObj({
        ...obj,
        FilterMod: FilterMod,
      });
      LoadTask();
    } catch (error) {
      toast.error("error");
    }
  };
  const LoadExpert = async () => {
    try {
      setLoading(true);
      const response = await Api_GetAllExpertAccessToCategory_Parent(objRef.current);
      console.log(response);
      setExpertList(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error("error");
    }
  };
  const LoadProject = async () => {
    try {
      setLoading(true);
      const response = await Api_GetAllProject_Parent(objRef.current);
      console.log("LoadProject:" + JSON.stringify(response));
      setProjectList(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error("error");
    }
  };
  const LoadTask = async () => {
    try {
      setLoading(true);
      const response = await Api_GetTasksByFilter(objRef.current);
      console.log("LoadTask:" + response);
      settaskList(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error("error");
    }
  };

  const onChangeFilterMod = (event) => {
    console.log(event);
    setObj({
      ...obj,
      FilterMod: event,
    });
    LoadTask();
  };
  useEffect(() => {
    LoadData();
  }, []);
  const onSelectItemProjectCategory = async (item) => {
    try {
      setcategoryName(item.text);
      setObj({
        ...obj,
        categoryID: item.id,
      });
      setmodalCategory(false);
      LoadExpert();
      LoadProject();
      LoadTask(objRef.current);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemExpert = async (item) => {
    try {
      setexpertName(item.text);
      setObj({
        ...obj,
        expertID: item.id,
      });
      LoadProject();
      setmodalExpert(false);
      LoadTask();
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemProject = async (item) => {
    try {
      setProjectName(item.text);
      setObj({
        ...obj,
        projectID: item.id,
      });
      setmodalProject(false);
      LoadTask();
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const themes = ["classic"];
  const [obj, setObj, objRef] = useState({
    categoryID: 0,
    expertID: 0,
    projectID: 0,
    progressMin: 0,
    progressMax: 100,
    FilterMod: FilterMod,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "taskName",
        header: "Task Name",
      },
      {
        header: "Project Name",
        Cell: ({ cell }) => <Link to={`/admin/project/Management/${cell.row.original.projectID}`}>{cell.row.original.projectName}</Link>,
      },
      {
        header: "Expert Name",
        Cell: ({ cell }) =>
          cell.row.original.expertID != null ? (
            cell.row.original.expertName
          ) : (
            <Button
              className="btn btn-success mr-5 btn-xs"
              onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/project/Refer/${cell.row.original.projectID}`)}
            >
              To Refer
            </Button>
          ),
      },

      {
        header: "Date Add",
        Cell: ({ cell }) => (cell.row.original.dateAdd != null ? cell.row.original.dateAdd.toString().substring(2, 10) : null),
      },
      {
        header: "Last Date Activity",
        Cell: ({ cell }) => (cell.row.original.lastDateActivity != null ? cell.row.original.lastDateActivity.toString().substring(2, 10) : null),
      },
      {
        header: "Progress",

        Cell: ({ cell }) => (
          <div className="project-status project-status-hover">
            <Media className="mb-0">
              <P>{cell.row.original.progress}% </P>
              <Media body className="text-end">
                <span>Done</span>
              </Media>
            </Media>
            {cell.row.original.progress == 100 ? (
              <Progress className="sm-progress-bar progress-hover" color="success" value={cell.row.original.progress} style={{ height: "5px" }} />
            ) : (
              <Progress
                className="sm-progress-bar progress-hover"
                striped
                color="primary"
                value={cell.row.original.progress}
                style={{ height: "5px" }}
              />
            )}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Todo" title={obj.FilterMod} />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Fragment>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Status"}</Label>
                        <Input
                          type="select"
                          name="FilterMod"
                          className="form-control"
                          value={obj.FilterMod}
                          onChange={(txt) => {
                            onChangeFilterMod(txt.target.value);
                          }}
                        >
                          <option key={"AllTasks"} value={"AllTasks"}>
                            All Tasks
                          </option>
                          <option key={"LatestActivities"} value={"LatestActivities"}>
                            Latest Activities
                          </option>
                          <option key={"NotToRefer"} value={"NotToRefer"}>
                            Not To Refer
                          </option>
                          <option key={"NotYetStarted"} value={"NotYetStarted"}>
                            Not Yet Started
                          </option>
                          <option key={"UnfinishedTasks"} value={"UnfinishedTasks"}>
                            Unfinished Tasks
                          </option>
                          <option key={"Done"} value={"Done"}>
                            Done Tasks
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Category"}</Label>

                        {ProjectCategoryList != null && ProjectCategoryList != "" ? (
                          <Input
                            className="form-control"
                            value={categoryName}
                            onClick={() => {
                              setmodalCategory(true);
                            }}
                            readOnly={true}
                          />
                        ) : (
                          <Input
                            className="form-control"
                            value={""}
                            onClick={() => {
                              toast.info("List Is Empty");
                            }}
                            readOnly={true}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Expert"}</Label>

                        {ExpertList != null && ExpertList != "" ? (
                          <Input
                            className="form-control"
                            value={expertName}
                            onClick={() => {
                              setmodalExpert(true);
                            }}
                            readOnly={true}
                          />
                        ) : (
                          <Input
                            className="form-control"
                            value={""}
                            onClick={() => {
                              toast.info("List Is Empty");
                            }}
                            readOnly={true}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Project"}</Label>

                        {ProjectList != null && ProjectList != "" ? (
                          <Input
                            className="form-control"
                            value={ProjectName}
                            onClick={() => {
                              setmodalProject(true);
                            }}
                            readOnly={true}
                          />
                        ) : (
                          <Input
                            className="form-control"
                            value={""}
                            onClick={() => {
                              toast.info("List Is Empty");
                            }}
                            readOnly={true}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {taskList != null ? (
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <MaterialReactTable
                    enableStickyHeader
                    muiTablePaperProps={{
                      elevation: 0,
                      sx: {
                        color: "#fff",
                        borderRadius: "0",
                        border: "0",
                        "& tbody tr:nth-of-type(odd)": {
                          backgroundColor: "#f5f5f5",
                        },
                        "& thead tr": {
                          backgroundColor: "#16449D",
                          color: "#fff",
                        },
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: (theme) => ({
                        color: "#fff",
                      }),
                    }}
                    muiTableHeadCellDragHandleProps={{
                      sx: (theme) => ({
                        color: "#fff",
                      }),
                    }}
                    muiTableHeadCellColumnActionsButtonProps={{
                      sx: (theme) => ({
                        color: "#fff",
                      }),
                    }}
                    muiTableHeadCellFilterTextFieldProps={{
                      sx: (theme) => ({
                        color: "#fff",
                      }),
                    }}
                    getRowId={(originalRow) => originalRow.Id}
                    columns={columns}
                    data={taskList}
                    enableColumnOrdering
                    enableExpanding
                    enablePagination={false}
                    onRowSelectionChange={setRowSelection}
                    state={{ rowSelection }}
                    tableInstanceRef={tableInstanceRef}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
      </Container>
      <Modal isOpen={modalCategory}>
        <ModalHeader>Project Category</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemProjectCategory}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ProjectCategoryList}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setcategoryName("");
              setmodalCategory(false);
              setObj({
                ...obj,
                categoryID: 0,
              });
              setExpertList(null);
              setexpertName("");
              setProjectList(null);
              setProjectName("");
              LoadTask();
            }}
          >
            Clean Select
          </Button>
          <Button color="light" onClick={() => setmodalCategory(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalExpert}>
        <ModalHeader>Select Expert</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemExpert}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ExpertList}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setexpertName("");
              setmodalExpert(false);
              setObj({
                ...obj,
                expertID: 0,
              });
              LoadTask();
              LoadProject();
            }}
          >
            Clean Select
          </Button>
          <Button
            color="light"
            onClick={() => {
              setmodalExpert(false);
              LoadProject();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalProject}>
        <ModalHeader>Select Project</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemProject}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ProjectList}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setProjectName("");
              setmodalProject(false);
              setObj({
                ...obj,
                projectID: 0,
              });
              LoadTask();
            }}
          >
            Clean Select
          </Button>
          <Button color="light" onClick={() => setmodalProject(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default ToDoProjectManagers;
