import React, { useEffect, useState, Fragment } from "react";
import { Api_List } from "../../../api/Admin/StaticText";
import { Btn, H6, Image, H5 } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, Table } from "reactstrap";

const StaticTextList = () => {
  const navigate = useNavigate();
  const [SelectetID, setSelectetID] = useState(0);
  const [modal, setModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const LoadData = async () => {
    const response = await Api_List();
    console.log(response);
    setTableData(response.data.data);
  };
  useEffect(() => {
    LoadData();
  }, []);

  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Post Category" title="List" />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {tableData ? (
                  <div className="table-responsive">
                    <Table>
                      <thead className="bg-primary">
                        <tr>
                          <th scope="col">{"Name"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.title}</td>
                            <td>
                              <span>
                                <Button className="btn btn-info mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/StaticTextList/edit/${item.id}`)}>
                                  Edit
                                </Button>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default StaticTextList;
