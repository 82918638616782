import React, { Fragment, useContext } from "react";
import { Media, Row, Col, Label, FormGroup, Input, Card, CardBody } from "reactstrap";

const ChatHeader = ({ TaskList, onChangeTask, obj, onChangeChatBy, typeName }) => {
  return (
    <Fragment>
      <Media className="chat-header clearfix">
        <Media body>
          <Row>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="exampleFormControlSelect9">{"Chat with"}</Label>
                <Input
                  type="select"
                  name="chatBy"
                  className="form-control digits"
                  value={obj.chatBy}
                  onChange={(txt) => {
                    onChangeChatBy(txt.target.value);
                  }}
                >
                  {typeName == "Expert" ? (
                    <>
                      <option value={"Administrator"} key={"Administrator"}>
                        Admin
                      </option>
                      <option value={"Recruiter"} key={"Recruiter"}>
                        Recruiter
                      </option>
                    </>
                  ) : typeName == "Recruiter" ? (
                    <>
                      <option value={"Expert"} key={"Expert"}>
                        Expert
                      </option>
                      <option value={"Administrator"} key={"Administrator"}>
                        Admin
                      </option>
                    </>
                  ) : typeName == "Administrator" ? (
                    <>
                      <option value={"Expert"} key={"Expert"}>
                        Expert
                      </option>
                      <option value={"Recruiter"} key={"Recruiter"}>
                        Recruiter
                      </option>
                      <option value={"ProjectManager"} key={"ProjectManager"}>
                        ProjectManager
                      </option>
                    </>
                  ) : typeName == "ProjectManager" ? (
                    <>
                      <option value={"Expert"} key={"Expert"}>
                        Expert
                      </option>
                      <option value={"Administrator"} key={"Administrator"}>
                        Admin
                      </option>
                      <option value={"Recruiter"} key={"Recruiter"}>
                        Recruiter
                      </option>
                    </>
                  ) : null}
                </Input>
              </FormGroup>
            </Col>
            <Col sm="8">
              <FormGroup>
                <Label htmlFor="exampleFormControlSelect9">{"Tasks"}</Label>
                <Input
                  type="select"
                  name="companyID"
                  className="form-control digits"
                  value={obj.taskID}
                  onChange={(txt) => {
                    onChangeTask(txt.target.value);
                  }}
                >
                  <option value={0} key={0}>
                    All Task...
                  </option>
                  {TaskList != null
                    ? TaskList.map((item) => {
                        return (
                          <option key={item.taskID} value={item.taskID}>
                            {item.projectCategoryTaskTitle}
                          </option>
                        );
                      })
                    : null}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Media>
      </Media>
    </Fragment>
  );
};
export default ChatHeader;
