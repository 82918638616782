import axios from "axios";
import { URL, Version } from "../URL";

const Api_MembersList = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Member`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_GetMember = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Member/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetMemberName = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Member/GetMemberName/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_EditMember = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/Member?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AddMember = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Member`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetMemberTypeList = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Member/GetMemberTypeList`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_ChangePassword = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Member/ChangePassword`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_DeleteMember = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/Member?id=${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_MemberProfile = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Member/MemberProfile`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_EditMyProfile = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/Member/EditMyProfile`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetImage = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Member/GetImage`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetImageByMemberID = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Member/GetImage/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export {
  Api_MemberProfile,
  Api_MembersList,
  Api_GetMember,
  Api_EditMember,
  Api_GetMemberTypeList,
  Api_DeleteMember,
  Api_AddMember,
  Api_ChangePassword,
  Api_GetMemberName,
  Api_EditMyProfile,
  Api_GetImageByMemberID,
  Api_GetImage,
};
