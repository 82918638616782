import React, { Fragment } from "react";
import Chart from "react-apexcharts";
import { Card, CardBody, Col, CardHeader } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { RadarChart } from "../../../Constant/index";

import { apexRadarPolygonfillCharts } from "./apexData";

const RadarChartClass = () => {
  return (
    <Fragment>
      <Col sm="12" xl="12" className="box-col-6">
        <Card>
          <CardHeader className="pb-0">
            <H5>performance Chart</H5>
          </CardHeader>
          <CardBody>
            <div id="radarchart">
              <Chart options={apexRadarPolygonfillCharts.options} series={apexRadarPolygonfillCharts.series} type="radar" height={350} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default RadarChartClass;
