import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";
import CKEditors from "react-ckeditor-component";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Api_Get, Api_Edit } from "../../../api/Admin/StaticText";
const StaticText = () => {
  const navigate = useNavigate();
  const { PostID } = useParams();
  const [obj, setObj] = useState({
    title: "",
    text: "",
  });
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [parentstemp, setParentstemp] = useState([]);
  const [parents, setParents] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await Api_Get(PostID);
        setObj(response.data);
        console.log(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);

  const SaveData = async () => {
    try {
      setLoadingBtn(true);
      const data = await Api_Edit(PostID, obj);
      console.log(data);
      if (data.response) {
        if (data.response.data.IsSuccess == false) {
          toast.error(data.response.data.Message);
        }
      } else if (data.data) {
        if (data.data.isSuccess == true) {
          toast.success("Information edited");
          navigate(`${process.env.PUBLIC_URL}/admin/StaticTextList`);
        }
      } else {
        toast.error("Error");
      }
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
    }
  };
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setObj({
      ...obj,
      text: newContent,
    });
  };
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Post Category" title="Edit" />
        <Row>
          {obj.name != "" ? (
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Form className="theme-form">
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label>{"Title"}</Label>
                            <InputGroup>
                              <Input name="title" readOnly={true} className="form-control" value={obj.title} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <Label>{"Text"}</Label>
                          <CardBody>
                            <CKEditors
                              activeclassName="p10"
                              content={obj.text}
                              events={{
                                change: onChange,
                              }}
                            />
                          </CardBody>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Btn
                            attrBtn={{
                              color: "primary",
                              className: "m-r-15",
                              type: "button",
                              onClick: () => SaveData(),
                            }}
                          >
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Btn>
                          <Btn
                            attrBtn={{
                              color: "light",
                              type: "button",
                              onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/StaticTextList`),
                            }}
                          >
                            Cancel
                          </Btn>
                        </Col>
                      </Row>
                    </Form>
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          ) : null}
        </Row>
      </Container>
    </Fragment>
  );
};

export default StaticText;
