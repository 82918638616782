import React, { Fragment } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Home } from "react-feather";
import H4 from "../Headings/H3Element";
import { Spinner } from "../../AbstractElements";
const Breadcrumbs = (props) => {
  const { title = "", parent = "", Loading = false } = props;
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="12" sm="6">
              <H4>
                {title}
                {Loading ? (
                  <div className="loader-box3">
                    <Spinner attrSpinner={{ className: "loader-15" }} />
                  </div>
                ) : null}
              </H4>
            </Col>
            <Col xs="12" sm="6">
              <div className="breadcrumb">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                      <Home />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>{parent}</BreadcrumbItem>
                  <BreadcrumbItem active>{title}</BreadcrumbItem>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
