import React, { Fragment, useContext } from "react";

import GoogleContext from "../../../_helper/GoogleChart";

import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DailyEarning from "./DailyEarning";
import Greeting from "./Greeting";
import NewsUpdates from "./NewsUpdate";
import OutgoingProject from "./OutgoingProject";
import RecentActivitys from "./RecentActivitys";
import TotalTrasactions from "./TotalTrasactions";
import Yearly from "./Yearly";
const BIScreen = () => {
  const val = true;
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="BI" title="B-I" />
        <Row>
          <TotalTrasactions />
        </Row>
      </Container>
    </Fragment>
  );
};

export default BIScreen;
