import { useEffect } from "react";
import {
  SettingtSvg,
  BlogSvg,
  BonusUISvg,
  ButtonsSvg,
  CalanderSvg,
  ChartsSvg,
  ChatSvg,
  ContactSvg,
  EcommerceSvg,
  EditorsSvg,
  EmailSvg,
  FAQSvg,
  FilemanagerSvg,
  FormsSvg,
  GallarySvg,
  HeaderBookmarkSvg,
  HomeSvg,
  ContenttSvg,
  IconsSvg,
  JobsearchSvg,
  KanbanSvg,
  KnowledgebaseSvg,
  LearningSvg,
  MapsSvg,
  OthersSvg,
  ProjectSvg,
  SamplePageSvg,
  SearchResultSvg,
  SocialappSvg,
  SupportTicketSvg,
  TablesSvg,
  TaskSvg,
  TodoSvg,
  UiKitsSvg,
  UsersComponentSvg,
  WidgetsSvg,
  AdminUsersSvg,
  UserSvg,
} from "../../Data/svgIcons";
const MENUEMPTY = [
  {
    menutitle: "MENUEMPTY",
  },
];
const MENURecruiter = [
  {
    menutitle: "Edit Profile",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/users/useredit`,
        icon: UserSvg,
        title: "Profile",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Calander",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`,
        bookmark: true,
        icon: CalanderSvg,
        title: "Calander",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Chat",
    Items: [
      {
        title: "Chat",
        icon: ChatSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/ChatList/Administrator`,
            type: "link",
            title: "Chat with admin",
          },
          {
            path: `${process.env.PUBLIC_URL}/ChatList/Expert`,
            type: "link",
            title: "Chat with Experts ",
          },
        ],
      },
    ],
  },

  {
    menutitle: "Project",
    Items: [
      {
        title: "Project",
        icon: ProjectSvg,
        type: "sub",

        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/project/projectlist/Doing`,
            type: "link",
            title: "Ongoing projects",
          },
          {
            path: `${process.env.PUBLIC_URL}/project/projectlist/Notyetstarted`,
            type: "link",
            title: "Not yet started",
          },
          {
            path: `${process.env.PUBLIC_URL}/project/projectlist/Delivered`,
            type: "link",
            title: "Delivered tasks",
          },
          {
            path: `${process.env.PUBLIC_URL}/project/projectlist/Done`,
            type: "link",
            title: "Archive of finished projects",
          },
          {
            path: `${process.env.PUBLIC_URL}/project/createlist`,
            title: "Task creation and referral form",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Report ",
    Items: [
      {
        title: "Report ",
        icon: SearchResultSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/email/mailbox`,
            type: "link",
            title: "Requested reports",
          },
          {
            path: `${process.env.PUBLIC_URL}/email/mailbox`,
            type: "link",
            title: "Reports provided",
          },
        ],
      },
    ],
  },
  {
    menutitle: "KPI",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/kpi`,
        bookmark: true,
        icon: JobsearchSvg,
        title: "KPI",
        type: "link",
      },
    ],
  },
  {
    menutitle: "B-I",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/BI`,
        bookmark: true,
        icon: WidgetsSvg,
        title: "B-I",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Contra-Pay",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/BI`,
        bookmark: true,
        icon: BonusUISvg,
        title: "Contra-Pay",
        type: "link",
      },
    ],
  },
];
const MENUExpert = [
  {
    menutitle: "Profile",
    Items: [{ path: `${process.env.PUBLIC_URL}/admin/profile`, icon: UsersComponentSvg, title: "Profile", type: "link" }],
  },
  {
    menutitle: "To-Do",
    Items: [
      {
        title: "To-Do",
        icon: TodoSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/AllTasks`,
            title: "Task Box",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/LatestActivities`,
            title: "Latest activities of the expert",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/admin/todo/NotYetStarted`,
            title: "Not Yet Started",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/UnfinishedTasks`,
            title: "Unfinished tasks",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/Done`,
            title: "Done",
            type: "link",
          },
        ],
      },
    ],
  },

  {
    menutitle: "Calander",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`,
        bookmark: true,
        icon: CalanderSvg,
        title: "Calander",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Projects",
    Items: [
      {
        title: "Projects",
        icon: ProjectSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Doing`,
            type: "link",
            title: "Ongoing projects",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Notyetstarted`,
            type: "link",
            title: "Not yet started",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Delivered`,
            type: "link",
            title: "Delivered tasks",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Done`,
            type: "link",
            title: "Archive of finished projects",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Chat",
    Items: [
      {
        title: "Chat",
        icon: ChatSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/ChatList/Recruiter`,
            type: "link",
            title: "Chat with Recruiters",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ChatList/Administrator`,
            type: "link",
            title: "Chat with Admin ",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Report ",
    Items: [
      {
        title: "Report ",
        icon: SearchResultSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/email/mailbox`,
            type: "link",
            title: "Requested reports",
          },
          {
            path: `${process.env.PUBLIC_URL}/email/mailbox`,
            type: "link",
            title: "Reports provided",
          },
        ],
      },
    ],
  },
  {
    menutitle: "KPI",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/kpi`,
        bookmark: true,
        icon: JobsearchSvg,
        title: "KPI",
        type: "link",
      },
    ],
  },
  {
    menutitle: "B-I",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/BI`,
        bookmark: true,
        icon: WidgetsSvg,
        title: "B-I",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Contra-Pay",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/BI`,
        bookmark: true,
        icon: BonusUISvg,
        title: "Contra-Pay",
        type: "link",
      },
    ],
  },
];
const MENUProjectManager = [
  {
    menutitle: "Profile",
    Items: [{ path: `${process.env.PUBLIC_URL}/admin/profile`, icon: UsersComponentSvg, title: "Profile", type: "link" }],
  },
  {
    menutitle: "To-Do",
    Items: [
      {
        title: "To-Do",
        icon: TodoSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/AllTasks`,
            title: "Task Box",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/LatestActivities`,
            title: "Latest activities of the expert",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/admin/todo/NotToRefer`,
            title: "Not To Refer",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/NotYetStarted`,
            title: "Not Yet Started",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/UnfinishedTasks`,
            title: "Unfinished tasks",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/Done`,
            title: "Done",
            type: "link",
          },
        ],
      },
    ],
  },

  {
    menutitle: "Calander",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`,
        bookmark: true,
        icon: CalanderSvg,
        title: "Calander",
        type: "link",
      },
    ],
  },

  {
    menutitle: "Projects",
    Items: [
      {
        title: "Projects",
        icon: ProjectSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Doing`,
            type: "link",
            title: "Ongoing projects",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Notyetstarted`,
            type: "link",
            title: "Not yet started",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Delivered`,
            type: "link",
            title: "Delivered tasks",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Done`,
            type: "link",
            title: "Archive of finished projects",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Chat",
    Items: [
      {
        title: "Chat",
        icon: ChatSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/ChatList/Recruiter`,
            type: "link",
            title: "Chat with recruiters",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ChatList/Expert`,
            type: "link",
            title: "Chat with Experts ",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ChatList/Administrator`,
            type: "link",
            title: "Chat with Admin",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Report ",
    Items: [
      {
        title: "Report ",
        icon: SearchResultSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/email/mailbox`,
            type: "link",
            title: "Requested reports",
          },
          {
            path: `${process.env.PUBLIC_URL}/email/mailbox`,
            type: "link",
            title: "Reports provided",
          },
        ],
      },
    ],
  },
  {
    menutitle: "KPI",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/kpi`,
        bookmark: true,
        icon: JobsearchSvg,
        title: "KPI",
        type: "link",
      },
    ],
  },
  {
    menutitle: "B-I",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/BI`,
        bookmark: true,
        icon: WidgetsSvg,
        title: "B-I",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Contra-Pay",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/BI`,
        bookmark: true,
        icon: BonusUISvg,
        title: "Contra-Pay",
        type: "link",
      },
    ],
  },
];
const MENUAdministrator = [
  {
    menutitle: "Administrator",
    Items: [
      {
        title: "Managers",
        icon: AdminUsersSvg,
        type: "sub",
        badge: false,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/Member/add`,
            title: "Create",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Members`,
            title: "List",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Recruiters",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/admin/Recruiters`,
        icon: UsersComponentSvg,
        title: "Recruiters",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Message",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/admin/Messages`,
        icon: EmailSvg,
        title: "Message",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Setting",
    Items: [
      {
        title: "Setting",
        icon: SettingtSvg,
        type: "sub",
        badge: false,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/StaticTextList`,
            title: "Static Text List",
            type: "link",
          },

          {
            title: " Countries ",
            type: "sub",
            children: [
              {
                title: "Countries",
                type: "link",
                path: `${process.env.PUBLIC_URL}/admin/Countries`,
              },
              {
                title: "New",
                type: "link",
                path: `${process.env.PUBLIC_URL}/admin/Country/Add`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menutitle: "Chat",
    Items: [
      {
        title: "Chat",
        icon: ChatSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/ChatList/Recruiter`,
            type: "link",
            title: "Chat with recruiters",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ChatList/Expert`,
            type: "link",
            title: "Chat with Experts ",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ChatList/ProjectManager`,
            type: "link",
            title: "Chat with ProjectManager",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Project",
    Items: [
      {
        title: "Project",
        icon: ProjectSvg,
        type: "sub",

        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Doing`,
            type: "link",
            title: "Ongoing projects",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Notyetstarted`,
            type: "link",
            title: "Not yet started",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Delivered`,
            type: "link",
            title: "Delivered tasks",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/project/projectlist/Done`,
            type: "link",
            title: "Archive of finished projects",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ProjectCategory`,
            title: "Category list",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ProjectCategory/Add`,
            title: "New Category",
            type: "link",
          },
        ],
      },
    ],
  },
  // {
  //   menutitle: "Tasks",
  //   Items: [
  //     {
  //       path: `${process.env.PUBLIC_URL}/tasks`,
  //       bookmark: true,
  //       icon: TaskSvg,
  //       title: "Tasks",
  //       type: "link",
  //     },
  //   ],
  // },
  {
    menutitle: "Calander",
    Items: [
      {
        title: "Calander",
        icon: CalanderSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`,
            type: "link",
            title: "Calander",
          },
          {
            path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`,
            type: "link",
            title: "Draggable",
          },
        ],
      },
    ],
  },
  {
    menutitle: "To-Do",
    Items: [
      {
        title: "To-Do",
        icon: TodoSvg,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/AllTasks`,
            title: "Task Box",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/LatestActivities`,
            title: "Latest activities of the expert",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/admin/todo/NotToRefer`,
            title: "Not To Refer",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/NotYetStarted`,
            title: "Not Yet Started",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/UnfinishedTasks`,
            title: "Unfinished tasks",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/todo/Done`,
            title: "Done",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Support Ticket",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/support-ticket`,
        icon: SupportTicketSvg,
        type: "link",
        active: false,
        title: "Support Ticket",
      },
    ],
  },
];
export { MENURecruiter, MENUExpert, MENUProjectManager, MENUAdministrator, MENUEMPTY };
