import React, { Fragment, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Label,
  InputGroup,
  Input,
  FormGroup,
  Form,
  CardBody,
  Button,
} from "reactstrap";
import {
  Api_EditSetting,
  Api_GetSetting,
} from "../../../api/Admin/SiteSetting";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";
import { Api_List } from "../../../api/Admin/Category";
const SiteInformation = () => {
  const [title, setTitle] = useState("");
  const [mailServe, setMailServe] = useState("");
  const [email, setEmail] = useState("");
  const [passwordEmail, setPasswordEmail] = useState("");
  const [metKey, setMetKey] = useState("");
  const [metDec, setMetDec] = useState("");
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [categorylist, setCategoryList] = useState([]);
  const navigate = useNavigate();
  const [MemberObj, setMemberObj] = useState({
    title: "",
    mailServer: "",
    email: "",
    passwordEmail: "",
    metKey: "",
    metDec: "",
    postcategory1: 0,
    postcategory2: 0,
  });

  const getData = async () => {
    const { data: response2 } = await Api_List();

    if (response2.isSuccess === true) {
      setCategoryList(response2.data);
    }

    const { data: response } = await Api_GetSetting();

    if (response.isSuccess === true) {
      setMemberObj({
        title: response.data.title,
        mailServer: response.data.dmailServerata,
        email: response.data.email,
        passwordEmail: response.data.passwordEmail,
        metKey: response.data.metKey,
        metDec: response.data.metDec,
        postcategory1: response.data.postcategory1,
        postcategory2: response.data.postcategory2,
      });

      console.log("MemberObj:" + JSON.stringify(MemberObj));
    } else if (response.isSuccess === false) {
      toast.error("some thing went wrong...");
    } else {
      toast.error("error");
    }
  };
  const SaveData = async () => {
    try {
      setLoadingBtn(true);
      const data = await Api_EditSetting(MemberObj);
      if (data.response) {
        if (data.response.data.isSuccess == false) {
          toast.error(data.response.data.message);
        }
      } else if (data.data) {
        if (data.data.isSuccess == true) {
          toast.success("Information edited");
        }
      } else {
        toast.error("Error");
      }
      console.log(data);

      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
    }
  };

  const onMemberChange = (event) => {
    setMemberObj({
      ...MemberObj,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const onChangeCategory1 = (val) => {
    if (val == 0) {
      setMemberObj({
        ...MemberObj,
        postcategory1: null,
      });
    } else {
      setMemberObj({
        ...MemberObj,
        postcategory1: val,
      });
    }
  };
  const onChangeCategory2 = (val) => {
    if (val == 0) {
      setMemberObj({
        ...MemberObj,
        postcategory2: null,
      });
    } else {
      setMemberObj({
        ...MemberObj,
        postcategory2: val,
      });
    }
  };
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Site Setting" title="Portal" />
        {MemberObj.title != "" && MemberObj.postcategory1 != 0 ? (
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={4}>
                      <FormGroup>
                        <Label>{"Title"}</Label>
                        <InputGroup>
                          <Input
                            name="title"
                            className="form-control"
                            value={MemberObj.title || ""}
                            onChange={onMemberChange}
                            required={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label>{"Mail Server"}</Label>
                        <InputGroup>
                          <Input
                            name="mailServer"
                            className="form-control"
                            value={MemberObj.mailServer || ""}
                            onChange={onMemberChange}
                            required={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label>{"Email"}</Label>
                        <InputGroup>
                          <Input
                            name="email"
                            className="form-control"
                            value={MemberObj.email || ""}
                            required={true}
                            onChange={onMemberChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label>{"Emial Password"}</Label>
                        <InputGroup>
                          <Input
                            name="passwordEmail"
                            className="form-control"
                            value={MemberObj.passwordEmail || ""}
                            onChange={onMemberChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label>{"Met Key"}</Label>
                        <InputGroup>
                          <Input
                            name="metKey"
                            className="form-control"
                            value={MemberObj.metKey || ""}
                            onChange={onMemberChange}
                            required={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label>{"Met Dec"}</Label>
                        <InputGroup>
                          <Input
                            name="metDec"
                            className="form-control"
                            value={MemberObj.metDec || ""}
                            onChange={onMemberChange}
                            required={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          {"Post Category 1"}
                        </Label>

                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          defaultValue={MemberObj.postcategory1}
                          onChange={(txt) => {
                            onChangeCategory1(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {categorylist.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          {"Post Category 2"}
                        </Label>

                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          defaultValue={MemberObj.postcategory2}
                          onChange={(txt) => {
                            onChangeCategory2(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {categorylist.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="mb-0">
                        <Btn
                          attrBtn={{
                            color: "primary",
                            className: "m-r-15",
                            type: "button",
                            disabled: LoadingBtn,
                            onClick: () => SaveData(),
                          }}
                        >
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner
                                attrSpinner={{ className: "loader-7" }}
                              />
                            </div>
                          ) : (
                            "Save"
                          )}
                        </Btn>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
      </Container>
    </Fragment>
  );
};

export default SiteInformation;
