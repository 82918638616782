import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs, P, H6 } from "../../../AbstractElements";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Media,
  CardFooter,
  Progress,
} from "reactstrap";
import {
  Api_SetTaskToExpert,
  Api_ListExpert,
  Api_ListExpertTaskProject,
  Api_ExpertAddTaskProject,
  Api_ListReferkProject,
  UpdateEnableTaskRefer,
  Api_UpdateEnableTaskRefer,
  Api_ProjectInfo,
  Api_ListTaskFor,
} from "../../../api/Admin/Project";
import { toast } from "react-toastify";
import DeniReactTreeView from "deni-react-treeview";
const ProjectRefer = (props) => {
  const [ExpertList, setExpertList] = useState([]);
  const [TaskList, setTaskList] = useState([]);
  const [modalExpert, setmodalExpert] = useState(false);
  const [objProject, setobjProject] = useState([]);
  const [expertID, setexpertID] = useState(0);
  const [SelectTaskID, setSelectTaskID] = useState(0);
  const { ProjectID } = useParams();
  const themes = ["classic"];
  const LoadData = async () => {
    const response = await Api_ListExpert(ProjectID);
    setExpertList(response.data.data);
    LoadTask();
    ProjectInfo();
  };
  useEffect(() => {
    LoadData();
  }, []);
  const LoadTask = async () => {
    const response = await Api_ListTaskFor(ProjectID);
    console.log("LoadTask:" + JSON.stringify(response.data.data));
    setTaskList(response.data.data);
  };
  const SaveData = async () => {
    // const response = await Api_ExpertAddTaskProject(ProjectID, expertID, tableData);
    // if (response.data.isSuccess && response.data.isSuccess == true) {
    //   toast.success("Saved");
    //   LoadDataRefer();
    // }
    // console.log(response.data.isSuccess);
  };
  const ProjectInfo = async () => {
    const response = await Api_ProjectInfo(ProjectID);
    console.log("ProjectInfo:" + JSON.stringify(response));
    setobjProject(response.data.data);
  };
  const onSelectItemExpert = async (item) => {
    try {
      setmodalExpert(false);
      const response = await Api_SetTaskToExpert(ProjectID, SelectTaskID, item.id);
      console.log("Api_SetTaskToExpert:" + JSON.stringify(response));
      if (response.data.isSuccess == true) {
        const AllData = [...TaskList];
        const DataIndex = AllData.findIndex((p) => p.taskID == SelectTaskID);
        const TempData = AllData[DataIndex];
        TempData.expertName = item.text;
        TempData.expertID = item.id;
        AllData[DataIndex] = TempData;
        setTaskList(AllData);
      } else {
        toast("Erro");
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Project" title="Project Refer" />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Fragment>
                  <Row>
                    <Col md="12">
                      <div className="project-box">
                        <H6>
                          <span>Recruiter Name:</span>
                          {objProject.authorFirstName} {objProject.authorLastName}
                        </H6>
                        <span
                          className={`badge ${
                            objProject.projectStatus === "Done"
                              ? "badge-success"
                              : objProject.projectStatus === "Cancel"
                              ? "badge-danger"
                              : objProject.projectStatus === "Notyetstarted"
                              ? "badge-warning"
                              : "badge-primary"
                          }`}
                        >
                          {objProject.projectStatus}
                        </span>
                        <H6>{objProject.title}</H6>
                        <Media>
                          <Media body>
                            <P>{objProject.categoryTitle}</P>
                          </Media>
                        </Media>
                        <P>{objProject.description}</P>
                        <Row className="details">
                          <Col xs="3">
                            <span>From Date</span>
                          </Col>
                          <Col xs="3" className="font-secondary">
                            {objProject.startingDate}
                          </Col>
                          <Col xs="3">
                            <span>To Date</span>
                          </Col>
                          <Col xs="3" className="font-secondary">
                            {objProject.endingDate}
                          </Col>
                          <Col xs="3">
                            <span>Budget</span>
                          </Col>
                          <Col xs="3" className="font-secondary">
                            £{objProject.budget}
                          </Col>
                        </Row>

                        <div className="project-status mt-4">
                          <Media className="mb-0">
                            <P>{objProject.progress}% </P>
                            <Media body className="text-end">
                              <span>Done</span>
                            </Media>
                          </Media>
                          {objProject.progress === "100" ? (
                            <Progress className="sm-progress-bar" color="secondary" value={objProject.progress} style={{ height: "5px" }} />
                          ) : (
                            <Progress className="sm-progress-bar" striped color="primary" value={objProject.progress} style={{ height: "5px" }} />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {TaskList != "" && TaskList != null ? (
                        <div className="table-responsive">
                          <Table>
                            <thead className="bg-primary">
                              <tr>
                                <th scope="col">{"Task"}</th>
                                <th scope="col">{"Expert"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TaskList.map((item) => (
                                <tr key={item.taskID}>
                                  <td>{item.title}</td>
                                  <td>
                                    <FormGroup className="form-group">
                                      {item.expertName != null ? (
                                        <span
                                          onClick={() => {
                                            setSelectTaskID(item.taskID);
                                            setmodalExpert(true);
                                          }}
                                          className="txt-success"
                                        >
                                          {item.expertName}
                                        </span>
                                      ) : (
                                        <span
                                          onClick={() => {
                                            setSelectTaskID(item.taskID);
                                            setmodalExpert(true);
                                          }}
                                          className="txt-danger"
                                        >
                                          Not Selected
                                        </span>
                                      )}
                                    </FormGroup>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* {tableData2 != "" ? (
        <Container fluid={true}>
          <Col sm="12">
            <Card>
              <CardBody>
                <Fragment>
                  <Row>
                    <Label htmlFor="exampleFormControlSelect9">{"Experts"}</Label>
                    <Col sm="12">
                      <div className="table-responsive">
                        <Table>
                          <thead className="bg-primary">
                            <tr>
                              <th scope="col">{"Task"}</th>
                              <th scope="col">{"Expert"}</th>
                              <th scope="col">{"Date"}</th>
                              <th scope="col">{"Progress"}</th>
                              <th scope="col">{"Enable"}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData2.map((item) => (
                              <tr key={item.id}>
                                <td>{item.projectCategoryTaskTitle}</td>
                                <td>{item.user2FullName}</td>
                                <td>{item.dateCreated}</td>
                                <td>
                                  <div className="project-status">
                                    <Media className="mb-0">
                                      <P>{item.progress}% </P>
                                      <Media body className="text-end">
                                        <span>Done</span>
                                      </Media>
                                    </Media>
                                    {item.progress === "100" ? <Progress className="sm-progress-bar" color="secondary" value={item.progress} style={{ height: "5px" }} /> : <Progress className="sm-progress-bar" striped color="primary" value={item.progress} style={{ height: "5px" }} />}
                                  </div>
                                </td>
                                <td>
                                  <FormGroup className="form-group">
                                    <div className="checkbox">
                                      <Input id={"checkboxR" + item.id} type="checkbox" defaultChecked={item.enable} onClick={() => onChangeEbanle(item.id)} />
                                      <Label className="text-muted" for={"checkboxR" + item.id}></Label>
                                    </div>
                                  </FormGroup>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Container>
      ) : null} */}
      <Modal isOpen={modalExpert}>
        <ModalHeader>Select Expert</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItemExpert}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ExpertList}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalExpert(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default ProjectRefer;
