import React, { Fragment, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes, HashRouter, useLocation } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { firebase_app, auth0, Jwt_token } from "../Config/Config";
import { configureFakeBackend, authHeader, handleResponse } from "../Services/Fack.Backend";
import Loader from "../Layout/Loader";
import LayoutRoutes from "./LayoutRoutes";
import Callback from "../Auth/Callback";
import { authRoutes } from "./AuthRoutes";
import PrivateRoute from "./PrivateRoute";
import Signin from "../Auth/Signin";
import Default from "../Pages/DashBoard/Default/Default";
import Layout from "../Layout/Layout";
import { useContext } from "react";

configureFakeBackend();
const Routers = () => {
  var token;

  const [login, setLogin] = useState();
  // const jwt_token = localStorage.getItem('token')};

  const [jwt_token, setJwt_token] = useState(async () => await localStorage.getItem("token"));
  const getToken = async () => {
    token = await localStorage.getItem("token");
    var login = await localStorage.getItem("login");
    // setJwt_token(token)
    setLogin(login);
    // console.log("token:" + token);
    //console.log("login:" + login);
  };

  getToken();

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    console.log("sss");
    let abortController = new AbortController();
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Fragment>
      <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}>
        <BrowserRouter basename={"/"}>
          <>
            <Suspense fallback={<Loader />}>
              <Routes>
                {/* <Route element={<Layout />}>
                  <Route path={Jwt_token ? `${process.env.PUBLIC_URL}/dashboardbb` : ""} element={Jwt_token ? <Default /> : <Route exact path={`${process.env.PUBLIC_URL}/pages/authentication/login`} element={<Signin />} />} />
                </Route> */}

                <Route path={"/"} element={<PrivateRoute />}>
                  {jwt_token ? (
                    <>
                      <Route element={<Layout />}>
                        <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Default />} />
                      </Route>

                      <Route path={`/*`} element={<LayoutRoutes />} />
                      <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} />
                      <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />} />
                    </>
                  ) : (
                    ""
                  )}

                  {jwt_token ? (
                    <>
                      <Route path={`/*`} element={<LayoutRoutes />} />
                    </>
                  ) : (
                    ""
                  )}
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
                <Route exact path={`${process.env.PUBLIC_URL}/pages/authentication/login/b`} element={<Signin />} />
                {authRoutes.map(({ path, Component }, i) => (
                  <Route path={path} element={Component} key={i} />
                ))}
              </Routes>
            </Suspense>
          </>
        </BrowserRouter>
      </Auth0Provider>
    </Fragment>
  );
};
export default Routers;
