import React, { Fragment, useContext } from "react";

import PieChartClass from "./PieChart";
import GoogleContext from "../../../_helper/GoogleChart";
import ChartistContext from "../../../_helper/Chartist";
import {
  AreaChart,
  BarChart2,
  BasicBarChart,
  ComboChart,
  GanttChart,
  LineChart,
  MaterialDesign,
  PieChart,
  SliceVisibilityThreshold,
  StackingAreaChart,
} from "../../../Constant";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";
import PieChart2Class from "./PieChart2";
import PieChart3Class from "./PieChart3";
import PieChart4Class from "./PieChart4";
import PieChart5Class from "./PieChart5";
import PieChart6Class from "./PieChart6";
import ComboChartClass from "./ComboChart";
import RadarChartClass from "./RadarChart";
import BasicAreaChartClass from "./BasicAreaChartClass";
import SimpleLineChartClass from "./SimpleLineChart";
import RadarChartClasstwo from "./chartjs/RadarChart";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
const KpiScreen = () => {
  const chartData = useContext(GoogleContext);
  const chartData2 = useContext(ChartistContext);
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="KPI" title="Exclusive statistics" />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <PieChartClass data={chartData.googleChart.GooglePieChart1} title={PieChart} digit={"1"} colClass="col-sm-12" />
                <RadarChartClass />
                <BasicAreaChartClass />
                <SimpleLineChartClass chartData={chartData2.chartistData} />
                <RadarChartClasstwo />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default KpiScreen;
