import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, H5, H6, Spinner, Btn, Image, H3 } from "../../../AbstractElements";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Media,
  CardFooter,
} from "reactstrap";
import user from "../../../assets/images/user/7.jpg";
import { Api_List } from "../../../api/Admin/ProjectCategory";
import { Api_GetLevelsMemberCheked } from "../../../api/Admin/CategoryLevel";
import { Api_GetMemberName, Api_GetImageByMemberID } from "../../../api/Admin/Member";
import { Api_AddTask } from "../../../api/Admin/MemberTask";

const MemberMappingTask = () => {
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { memberID } = useParams();
  const [userImg, setUserImg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [categoryid, setCategoryid] = useState(0);
  const [member, setMember] = useState("");
  const [objCategory, setobjCategory] = useState([]);
  const LoadData = async () => {
    const response = await Api_List();
    console.log(response);
    setobjCategory(response.data.data);

    const response2 = await Api_GetMemberName(memberID);
    setMember(response2.data.data.firstName + " " + response2.data.data.lastName);

    const { data: response3 } = await Api_GetImageByMemberID(memberID);
    if (response3.isSuccess == true) {
      if (response3.data.img != "") {
        setUserImg(response3.data.img);
      }
    }
  };
  useEffect(() => {
    LoadData();
  }, []);
  const onChange = async (val) => {
    setCategoryid(val);
    if (val == 0) {
      setTableData([]);
    } else {
      const response = await Api_List(val, memberID);
      setTableData(response.data.data);
      console.log("response:" + JSON.stringify(response));
      LoadLevels(val);
    }
  };
  const LoadLevels = async (val) => {
    setCategoryid(val);
    if (val == 0) {
      setTableData([]);
    } else {
      const response3 = await Api_GetLevelsMemberCheked(memberID, val);
      console.log(response3);
      setTableData(response3.data.data);
    }
  };

  const onChangeActive = async (key) => {
    const AllData = [...tableData];
    const DataIndex = AllData.findIndex((p) => p.id == key);
    const TempData = AllData[DataIndex];
    TempData.checked = !TempData.checked;
    AllData[DataIndex] = TempData;
    setTableData(AllData);
  };
  const SaveData = async () => {
    const response = await Api_AddTask(memberID, categoryid, tableData);
    if (response.data.isSuccess && response.data.isSuccess == true) {
      toast.success("Saved");
      navigate(`${process.env.PUBLIC_URL}/admin/Members`);
    }
    console.log(response.data.isSuccess);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="edit-profile">
          <Breadcrumbs parent="Portal" title="Member Mapping Task" Loading={Loading} />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Form className="theme-form">
                      <Row className="mb-2">
                        <div className="profile-title">
                          <Media>
                            {userImg == "" ? (
                              <Image
                                attrImage={{
                                  className: "img-70 rounded-circle",
                                  alt: "",
                                  src: `${user}`,
                                }}
                              />
                            ) : (
                              <Image
                                attrImage={{
                                  className: "img-70 rounded-circle",
                                  alt: "",
                                  src: userImg,
                                }}
                              />
                            )}
                            <Media body>
                              <H3 attrH3={{ className: "mb-1 f-20 txt-primary" }}>
                                <Link to={`${process.env.PUBLIC_URL}/admin/Member/edit/${memberID}`}>{member}</Link>
                              </H3>
                            </Media>
                          </Media>
                        </div>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect9">{"Project Category"}</Label>
                            {objCategory != "" ? (
                              <Input
                                type="select"
                                name="select"
                                className="form-control digits"
                                onChange={(txt) => {
                                  onChange(txt.target.value);
                                }}
                              >
                                <option value={0} key={0}>
                                  Select...
                                </option>
                                {objCategory.map((item) => {
                                  return (
                                    <option key={item.Id} value={item.Id}>
                                      {item.Title}
                                    </option>
                                  );
                                })}
                              </Input>
                            ) : null}
                          </FormGroup>
                        </Col>
                        {tableData != null && tableData != "" ? (
                          <Col sm="12">
                            <Row>
                              <div className="card">
                                <div className="table-responsive">
                                  <table className="table table-hover">
                                    <thead className="bg-primary">
                                      <tr>
                                        <th scope="col">Level</th>
                                        <th scope="col">Access</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tableData.map((item) => {
                                        return (
                                          <tr key={item.id}>
                                            <th scope="row">{item.title}</th>
                                            <td>
                                              <FormGroup className="form-group">
                                                <div className="checkbox">
                                                  <Input
                                                    id={item.id + "checkboxSee"}
                                                    type="checkbox"
                                                    defaultChecked={item.checked}
                                                    onClick={() => onChangeActive(item.id)}
                                                  />
                                                  <Label className="text-muted" for={item.id + "checkboxSee"}></Label>
                                                </div>
                                              </FormGroup>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Row>
                          </Col>
                        ) : null}
                      </Row>
                    </Form>
                  </Fragment>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col sm="12">
                      {tableData != "" ? (
                        <Button className="btn btn-success btn-md" onClick={() => SaveData()}>
                          Save
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default MemberMappingTask;
