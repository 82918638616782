import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Media, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import { Btn, H4, H3, H6, Image, P } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { EditProfile, Company, Username, UsersCountryMenu, AboutMe, UpdateProfile, FirstName, LastName, Address, EmailAddress, PostalCode, Country, City } from "../../../Constant";
import user from "../../../assets/images/user/7.jpg";
import { Api_AllCountry, Api_EditMyProfile, Api_UserMyProfile, Api_GetImage } from "../../../api/Admin/User";
import { URL, Version } from "../../../api/URL";
const EditMyProfile = () => {
  const [CountryList, setCountryList] = useState([]);
  const [userImg, setUserImg] = useState("");
  const [modal, setModal] = useState(false);
  const [obj, setObj] = useState({
    firttName: "",
    lastName: "",
    fullName: "",
    phoneNumber: "",
    countryID: 0,
    companyName: "",
    city: "",
    address: "",
    zipCode: "",
    gender: "",
    webSite: "",
    aboute: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: obj,
  });
  const onEditSubmit = async (val) => {
    const data = await Api_EditMyProfile(val);
    if (data.data.isSuccess == true) {
      toast.success("Your profile has been updated");
    } else {
      toast.error("Error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await Api_AllCountry();
        console.log(JSON.stringify(response.data));
        setCountryList(response.data);

        const { data: response2 } = await Api_UserMyProfile();
        console.log("response2:" + JSON.stringify(response2));
        reset(response2);
        setObj(response2);
        console.log("companyName:" + obj.companyName);

        const { data: response3 } = await Api_GetImage();
        if (response3.data.img != "") {
          setUserImg(response3.data.img);
        }
        console.log(JSON.stringify(response3));
      } catch (err) {}
    };

    fetchData();
  }, [reset]);

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    if (status === "done") {
      let response = JSON.parse(xhr.response);
      if (response.isSuccess == true) {
        toast.success("Your avatar has been successfully uploaded");
        setModal(false);

        const { data: response3 } = await Api_GetImage();
        if (response3.data.img != "") {
          setUserImg(response3.data.img + "");
        }
        console.log(JSON.stringify(response3));
      } else {
        toast.error("Your avatar could not be loaded");
      }
    }
  };
  const getUploadParams = async ({ meta }) => {
    const token = await localStorage.getItem("token");

    return {
      url: `${URL}${Version}/Users/UploadImage`,
      headers: { Authorization: `Bearer ${token}` },
    };
  };
  const styles = { width: "100%" };
  return (
    <Fragment>
      <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
        <CardHeader className="pb-0">
          <H4 attrH4={{ className: "card-title mb-0" }}>{EditProfile}</H4>
          <div className="card-options">
            <a className="card-options-collapse" href="#javascript">
              <i className="fe fe-chevron-up"></i>
            </a>
            <a className="card-options-remove" href="#javascript">
              <i className="fe fe-x"></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Row className="mb-2">
            <div className="profile-title">
              <Media>
                {userImg == "" ? (
                  <Image
                    attrImage={{
                      className: "img-70 rounded-circle",
                      alt: "",
                      src: `${user}`,
                      onClick: () => setModal(true),
                    }}
                  />
                ) : (
                  <Image
                    attrImage={{
                      className: "img-70 rounded-circle",
                      alt: "",
                      src: userImg,
                      onClick: () => setModal(true),
                    }}
                  />
                )}
                <Media body>
                  <H3 attrH3={{ className: "mb-1 f-20 txt-primary" }}>
                    <Link to={`${process.env.PUBLIC_URL}/users/userprofile`}>{obj.fullName}</Link>
                  </H3>
                </Media>
              </Media>
            </div>
          </Row>
          <Row>
            <Col sm="6" md="4">
              <FormGroup>
                {" "}
                <Label className="form-label">{EmailAddress}</Label>
                <input className="form-control" type="email" name="userName" placeholder="Email" disabled {...register("userName", { required: true })} />
                <span style={{ color: "red" }}>{errors.email && "Email Address is required"} </span>
              </FormGroup>
            </Col>

            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">{"First Name"}</Label>
                <input className="form-control" name="firstName" type="text" {...register("firstName", { required: true })} />
                <span style={{ color: "red" }}>{errors.fullName && "FirstName is required"} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">{"Last Name"}</Label>
                <input className="form-control" name="lastName" type="text" {...register("lastName", { required: true })} />
                <span style={{ color: "red" }}>{errors.fullName && "lastName is required"} </span>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup className="mb-4">
                {" "}
                <Label className="form-label">{Company}</Label>
                <input className="form-control" name="companyName" type="text" placeholder="Company" {...register("companyName")} />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label className="form-label">{"Gender"}</Label>
                <select name="gender" type="select" className="form-gender form-select" {...register("gender", { required: true, minValue: 1 })}>
                  <option value="" key={0}>
                    Select...
                  </option>
                  <option value="Male" key="Male">
                    {"Male"}
                  </option>
                  <option value="Female" key="Female">
                    {"Female"}
                  </option>
                </select>
                <span style={{ color: "red" }}>{errors.gender && "Gender is required"} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">{"Phone Number"}</Label>
                <input className="form-control" name="phoneNumber" type="text" placeholder="Phone" {...register("phoneNumber")} />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">{"WebSite"}</Label>
                <input className="form-control" name="webSite" type="text" placeholder="WebSite" {...register("webSite")} />
              </FormGroup>
            </Col>

            <Col md="4">
              <FormGroup>
                <Label className="form-label">{Country}</Label>
                <select name="countryID" type="select" className="form-control form-select" {...register("countryID")}>
                  <option value={0} key={0}>
                    Select...
                  </option>
                  {CountryList.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                {" "}
                <Label className="form-label">{City}</Label>
                <input className="form-control" type="text" name="city" placeholder="City" {...register("city", { required: true })} />
                <span style={{ color: "red" }}>{errors.city && "City is required"} </span>
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">{PostalCode}</Label>
                <input className="form-control" type="number" name="zipCode" placeholder="ZIP Code" {...register("zipCode")} />
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup>
                <Label className="form-label">{Address}</Label>
                <input className="form-control" name="address" type="text" placeholder="Home Address" {...register("address")} />
              </FormGroup>
            </Col>
            <Col md="12">
              <div>
                {" "}
                <Label className="form-label">{AboutMe}</Label>
                <textarea className="form-control" rows="5" name="aboute" placeholder="Enter About your description" {...register("aboute")}></textarea>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: "primary", type: "submit" }}>{UpdateProfile}</Btn>
        </CardFooter>
      </Form>
      <Modal isOpen={modal}>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept="image/*"
                inputContent="Drop files here or click to upload."
                styles={{
                  dropzone: { width: "100%", height: 50 },
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default EditMyProfile;
