import React, { Fragment } from "react";
import Chart from "react-apexcharts";

import { BasicAreaChart } from "../../../Constant/index";
import { apexAreaChart } from "./apexData";
import { Card, CardBody, Col, CardHeader } from "reactstrap";
import { H5 } from "../../../AbstractElements";

const BasicAreaChartClass = () => {
  return (
    <Fragment>
      <Col sm="12" xl="12" className="box-col-12">
        <Card>
          <CardHeader className="pb-0">
            <H5>Expert performance</H5>
          </CardHeader>
          <CardBody>
            <div id="basic-apex">
              <Chart options={apexAreaChart.options} series={apexAreaChart.series} type="area" height={350} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default BasicAreaChartClass;
