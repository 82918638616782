import { H6, LI, P, UL, Image, Btn } from "../../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { Col, Media, Progress, Row, Button } from "reactstrap";

const AllProjectDataExpert = ({ allProject }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Row>
        {allProject.map((item) => (
          <Col md="6 col-xxl-6" key={item.id}>
            <div className="project-box">
              <H6>
                <span>Recruiter Name:</span>
                {item.authorFirstName}
                {item.authorLastName}
              </H6>
              <span
                className={`badge ${
                  item.projectStatus === "Done"
                    ? "badge-success"
                    : item.projectStatus === "Cancel"
                    ? "badge-danger"
                    : item.projectStatus === "Notyetstarted"
                    ? "badge-warning"
                    : "badge-primary"
                }`}
              >
                {item.projectStatus}
              </span>
              <H6>{item.title}</H6>
              <Media>
                <Media body>
                  <span>Category :</span> {item.categoryTitle}
                </Media>
              </Media>
              <P>{item.description}</P>
              <Row className="details">
                <Col xs="3">
                  <span>From Date</span>
                </Col>
                <Col xs="3" className="font-secondary">
                  {item.startingDate}
                </Col>
                <Col xs="3">
                  <span>To Date</span>
                </Col>
                <Col xs="3" className="font-secondary">
                  {item.endingDate}
                </Col>

                <Col xs="3">
                  <span>Budget</span>
                </Col>
                <Col xs="3" className="font-secondary">
                  £{item.budget}
                </Col>
              </Row>

              <div className="project-status mt-4">
                <Media className="mb-0">
                  <P>{item.progress}% </P>
                  <Media body className="text-end">
                    <span>Done</span>
                  </Media>
                </Media>
                {item.progress == 100 ? (
                  <Progress className="sm-progress-bar" color="success" value={item.progress} style={{ height: "5px" }} />
                ) : item.progress > 0 && item.progress < 100 ? (
                  <Progress className="sm-progress-bar" striped color="primary" value={item.progress} style={{ height: "5px" }} />
                ) : (
                  <Progress className="sm-progress-bar" striped color="warning" value={item.progress} style={{ height: "5px" }} />
                )}
              </div>
              <div className="project-status mt-4">
                <Row className="details">
                  <Col xs="12">
                    <Button
                      className="btn btn-success mr-5 btn-xs"
                      onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/project/Management/${item.id}`)}
                    >
                      Management
                    </Button>
                    <Button
                      className="btn btn-success mr-5 btn-xs"
                      onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/chat/Administrator/${item.id}`)}
                    >
                      Chat By Admin
                    </Button>
                    <Button
                      className="btn btn-success mr-5 btn-xs"
                      onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/chat/Recruiter/${item.id}`)}
                    >
                      Chat By Recruiter
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};
export default AllProjectDataExpert;
