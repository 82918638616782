import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Media, Modal, ModalBody, ModalFooter, Button, Container } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import { Btn, H4, H3, H6, Image, P } from "../../../AbstractElements";
import { Link, useParams } from "react-router-dom";
import { EditProfile, Company, Username, UsersCountryMenu, AboutMe, UpdateProfile, FirstName, LastName, Address, EmailAddress, PostalCode, Country, City } from "../../../Constant";
import user from "../../../assets/images/user/7.jpg";
import { Api_AllCountry, Api_GetImageByUserID, Api_EditUser, Api_GetUser } from "../../../api/Admin/User";

import { URL, Version } from "../../../api/URL";
const EditUser = () => {
  const { RecruiterID } = useParams();
  const [CountryList, setCountryList] = useState([]);
  const [userImg, setUserImg] = useState("");
  const [modal, setModal] = useState(false);
  const [obj, setObj] = useState({
    userName: "",
    firstName: "",
    lasttName: "",
    phoneNumber: "",
    countryID: 0,
    companyName: "",
    city: "",
    address: "",
    zipCode: "",
    gender: "",
    webSite: "",
    aboute: "",
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: obj,
  });
  const onEditSubmit = async (val) => {
    const data = await Api_EditUser(RecruiterID, val);

    if (data.status == "200" && data.data.isSuccess == true) {
      toast.success("User profile has been saved");
    } else if (data.status === "200" && data.data.isSuccess === false) {
      toast.error(data.data.message);
    } else {
      toast.error("Error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await Api_AllCountry();
        // console.log(JSON.stringify(response.data));
        setCountryList(response.data);

        const { data: response2 } = await Api_GetUser(RecruiterID);
        // console.log("response2:" + JSON.stringify(response2.data));
        reset(response2.data);
        setObj(response2.data);

        const { data: response3 } = await Api_GetImageByUserID(RecruiterID);

        if (response3.data.img != "") {
          setUserImg(response3.data.img);
        }
        console.log("response3:" + JSON.stringify(response3));
      } catch (err) {}
    };

    fetchData();
  }, [reset]);

  const styles = { width: "100%" };
  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Users Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col xl="12">
              <Fragment>
                <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
                  <CardHeader className="pb-0">
                    <H4 attrH4={{ className: "card-title mb-0" }}>{EditProfile}</H4>
                    <div className="card-options">
                      <a className="card-options-collapse" href="#javascript">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="#javascript">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-2">
                      <div className="profile-title">
                        <Media>
                          {userImg == "" ? (
                            <Image
                              attrImage={{
                                className: "img-70 rounded-circle",
                                alt: "",
                                src: `${user}`,
                                onClick: () => setModal(true),
                              }}
                            />
                          ) : (
                            <Image
                              attrImage={{
                                className: "img-70 rounded-circle",
                                alt: "",
                                src: userImg,
                                onClick: () => setModal(true),
                              }}
                            />
                          )}
                          <Media body>
                            <H3 attrH3={{ className: "mb-1 f-20 txt-primary" }}>
                              <Link to={`${process.env.PUBLIC_URL}/users/userprofile`}>
                                {obj.firstName} {obj.lastName}
                              </Link>
                            </H3>
                          </Media>
                        </Media>
                      </div>
                    </Row>
                    <Row>
                      <Col sm="6" md="4">
                        <FormGroup>
                          {" "}
                          <Label className="form-label">{EmailAddress}</Label>
                          <input className="form-control" type="email" name="userName" placeholder="Email" disabled {...register("userName", { required: true })} />
                          <span style={{ color: "red" }}>{errors.email && "Email Address is required"} </span>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-4">
                          {" "}
                          <Label className="form-label">{Company}</Label>
                          <input className="form-control" name="companyName" type="text" placeholder="Company" {...register("companyName")} />
                        </FormGroup>
                      </Col>

                      <Col sm="6" md="4">
                        <FormGroup>
                          <Label className="form-label">{"First Name"}</Label>
                          <input className="form-control" name="firstName" type="text" {...register("firstName", { required: true })} />
                          <span style={{ color: "red" }}>{errors.firstName && "Nick Name is required"} </span>
                        </FormGroup>
                      </Col>
                      <Col sm="6" md="4">
                        <FormGroup>
                          <Label className="form-label">{"Last Name"}</Label>
                          <input className="form-control" name="lastName" type="text" {...register("lastName", { required: true })} />
                          <span style={{ color: "red" }}>{errors.lastName && "Nick Name is required"} </span>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Label className="form-label">{"Gender"}</Label>
                          <select
                            name="gender"
                            type="select"
                            className="form-gender form-select"
                            {...register("gender", {
                              required: true,
                              minValue: 1,
                            })}
                          >
                            <option value="" key={0}>
                              Select...
                            </option>
                            <option value="Male" key="Male">
                              {"Male"}
                            </option>
                            <option value="Female" key="Female">
                              {"Female"}
                            </option>
                          </select>
                          <span style={{ color: "red" }}>{errors.gender && "Gender is required"} </span>
                        </FormGroup>
                      </Col>
                      <Col sm="6" md="4">
                        <FormGroup>
                          <Label className="form-label">{"Phone Number"}</Label>
                          <input className="form-control" name="phoneNumber" type="text" placeholder="Phone" {...register("phoneNumber")} />
                        </FormGroup>
                      </Col>
                      <Col sm="6" md="4">
                        <FormGroup>
                          <Label className="form-label">{"WebSite"}</Label>
                          <input className="form-control" name="webSite" type="text" placeholder="WebSite" {...register("webSite")} />
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup>
                          <Label className="form-label">{Country}</Label>
                          <select name="countryID" type="select" className="form-control form-select" {...register("countryID")}>
                            <option value={0} key={0}>
                              Select...
                            </option>
                            {CountryList.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col sm="6" md="4">
                        <FormGroup>
                          {" "}
                          <Label className="form-label">{City}</Label>
                          <input className="form-control" type="text" name="city" placeholder="City" {...register("city", { required: true })} />
                          <span style={{ color: "red" }}>{errors.city && "City is required"} </span>
                        </FormGroup>
                      </Col>
                      <Col sm="6" md="4">
                        <FormGroup>
                          <Label className="form-label">{PostalCode}</Label>
                          <input className="form-control" type="number" name="zipCode" placeholder="ZIP Code" {...register("zipCode")} />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{Address}</Label>
                          <input className="form-control" name="address" type="text" placeholder="Home Address" {...register("address")} />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <div>
                          {" "}
                          <Label className="form-label">{AboutMe}</Label>
                          <textarea className="form-control" rows="5" name="aboute" placeholder="Enter About your description" {...register("aboute")}></textarea>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-end">
                    <Btn attrBtn={{ color: "primary", type: "submit" }}>{UpdateProfile}</Btn>
                  </CardFooter>
                </Form>
              </Fragment>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default EditUser;
