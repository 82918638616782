import axios from "axios";
import { URL, Version } from "../URL";

const Api_List = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Posts`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Get = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Posts/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Edit = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/Posts?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export { Api_List, Api_Edit, Api_Get };
