import axios from "axios";
import { URL, Version } from "../URL";

const Users = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_UserList = async () => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetUser = async (id) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Users/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_EditUser = async (id, data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(`${URL}${Version}/Users?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};

const Api_DeleteUser = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.delete(
      `${URL}${Version}/Users?id=${id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_AllCountry = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Country`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_EditMyProfile = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.put(
      `${URL}${Version}/Users/UserEditMyProfile`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_UserMyProfile = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${URL}${Version}/Users/UserMyProfile`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_ChangePassword = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${URL}${Version}/Users/ChangePassword`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetImage = async () => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Users/GetImage`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_GetImageByUserID = async (id) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.get(`${URL}${Version}/Users/GetImage/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export {
  Users,
  Api_UserList,
  Api_GetUser,
  Api_DeleteUser,
  Api_EditUser,
  Api_AllCountry,
  Api_EditMyProfile,
  Api_UserMyProfile,
  Api_ChangePassword,
  Api_GetImage,
  Api_GetImageByUserID,
};
