import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";
import { Api_Add, Api_ListParent, Api_Get, Api_Edit } from "../../../api/Admin/ProjectCategory";
import DeniReactTreeView from "deni-react-treeview";
const NewProjectCategory = () => {
  const navigate = useNavigate();
  const [LoadingBtn, setLoadingBtn] = useState(false);

  const [modalParent, setmodalParent] = useState(false);
  const [parentName, setparentName] = useState("");
  const { ProjectCategoryID } = useParams();
  const [obj, setObj] = useState({
    title: "",
    parentCategoryId: null,
    activeOrder: false,
  });
  const themes = ["classic"];
  const [parents, setParents] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response2 } = await Api_ListParent();
        setParents(response2.data);

        if (ProjectCategoryID) {
          const response = await Api_Get(ProjectCategoryID);

          setObj(response.data.data);
          setparentName(response.data.data.parentCategoryTitle);
        }
      } catch (err) {}
    };

    fetchData();
  }, []);
  const onMemberChange = (event) => {
    setObj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const SaveData = async () => {
    if (ProjectCategoryID) {
      setLoadingBtn(true);
      const data = await Api_Edit(ProjectCategoryID, obj);
      console.log(data);
      if (data.response) {
        if (data.response.data.IsSuccess == false) {
          toast.error(data.response.data.Message);
        }
      } else if (data.data) {
        if (data.data.isSuccess == true) {
          toast.success("Information edited");
          navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategory`);
        }
      } else {
        toast.error("Error");
      }
      setLoadingBtn(false);
    } else {
      setLoadingBtn(true);
      const data = await Api_Add(obj);
      setLoadingBtn(false);
      console.log(data);
      if (data.response) {
        if (data.response.data.IsSuccess == false) {
          toast.error(data.response.data.Message);
        }
      } else if (data.data) {
        if (data.data.isSuccess == true) {
          toast.success("A new category was created");
          navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategory`);
        }
      } else {
        toast.error("Error");
      }
    }
  };

  const onChangeActive = (val) => {
    setObj({
      ...obj,
      activeOrder: val,
    });
  };
  const onSelectItemParent = async (item) => {
    try {
      setparentName(item.text);
      setObj({
        ...obj,
        parentCategoryId: item.id,
      });
      setmodalParent(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Post Category" title="New" />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Fragment>
                  <Form className="theme-form">
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label>{"Name"}</Label>
                          <InputGroup>
                            <Input name="title" className="form-control" value={obj.title} onChange={onMemberChange} required />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">{"Parent"}</Label>

                          {parents != null && parents != "" ? (
                            <Input
                              className="form-control"
                              value={parentName}
                              onClick={() => {
                                setmodalParent(true);
                              }}
                              readOnly={true}
                            />
                          ) : (
                            <Input
                              className="form-control"
                              value={""}
                              onClick={() => {
                                toast.info("List Is Empty");
                              }}
                              readOnly={true}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col
                        sm="12"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          alignSelf: "center",
                        }}
                      >
                        <FormGroup className="form-group">
                          <div className="checkbox">
                            <Input id="checkbox1" type="checkbox" checked={obj.activeOrder} onChange={() => onChangeActive(!obj.activeOrder)} />
                            <Label className="text-muted" for="checkbox1">
                              Active Order
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Btn
                          attrBtn={{
                            color: "success",
                            className: "m-r-15",
                            type: "button",
                            disabled: LoadingBtn,
                            onClick: () => SaveData(),
                          }}
                        >
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : ProjectCategoryID ? (
                            "Save"
                          ) : (
                            "Add"
                          )}
                        </Btn>
                        <Btn
                          attrBtn={{
                            color: "light",
                            type: "button",
                            onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategory`),
                          }}
                        >
                          Cancel
                        </Btn>
                      </Col>
                    </Row>
                  </Form>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modalParent}>
        <ModalHeader>Parent</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemParent}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={parents}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setObj({
                ...obj,
                parentCategoryId: null,
              });
              setparentName("");
              setmodalParent(false);
            }}
          >
            Clean Select
          </Button>
          <Button color="light" onClick={() => setmodalParent(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default NewProjectCategory;
