import React, { Fragment, useContext, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Chatting from "./Chatting";
import MemberTypeContext from "../../../_helper/MemberType";
import { Breadcrumbs } from "../../../AbstractElements";
import { useParams } from "react-router-dom";
const ChatAppContain = () => {
  const { ChatBy } = useParams();
  const { typeName } = useContext(MemberTypeContext);
  const [objBreadcrumbsTitle, setobjBreadcrumbsTitle] = useState("");
  return (
    <Fragment>
      <Breadcrumbs parent="Chat" title={"Chat with " + objBreadcrumbsTitle} />
      <Container fluid={true}>
        <Row>
          <Col className="call-chat-body">
            <Card>
              <CardBody className="p-0">
                <Chatting typeName={typeName} setobjBreadcrumbsTitle={setobjBreadcrumbsTitle} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ChatAppContain;
