import React, { Fragment, useEffect, useRef, useMemo } from "react";
import { CheckCircle, Info, PlusCircle, Target, XCircle, Loader, Disc } from "react-feather";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavLink,
  NavItem,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { Link, useNavigate, useParams } from "react-router-dom";
import DeniReactTreeView from "deni-react-treeview";
import useState from "react-usestateref";
import AllProjectData from "./AllProjectDataExpert";
import { Api_MemberAccessToCategory_Parent } from "../../../../api/Admin/MemberTask";
import { toast } from "react-toastify";
import { Api_ProjectList } from "../../../../api/Admin/Project";
import { Spinner, Alerts } from "../../../../AbstractElements";
const ProjectDataListExpert = () => {
  const { FilterMod } = useParams();
  const [ProjectList, setProjectList] = useState([]);
  const [ProjectCategoryList, setProjectCategoryList] = useState([]);
  const [modalCategory, setmodalCategory] = useState(false);
  const [categoryName, setcategoryName] = useState("");
  const [Loading, setLoading] = useState(false);
  const [ShowemptyList, setShowemptyList] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      setObj({
        ...obj,
        ProjectStatus: FilterMod,
      });
      setLoading(true);
      console.log("FilterMod:" + FilterMod);
      const response = await Api_MemberAccessToCategory_Parent();
      setProjectCategoryList(response.data.data);
      LoadProject();
      setLoading(false);
    } catch (err) {
      console.log("err:" + err);
      setLoading(false);
    }
  };

  const [obj, setObj, objRef] = useState({
    categoryID: 0,
    progressMin: 0,
    progressMax: 100,
    ProjectStatus: "Doing",
  });
  const onChangeFilterMod = (event) => {
    console.log(event);
    setObj({
      ...obj,
      ProjectStatus: event,
    });
    LoadProject();
  };
  const themes = ["classic"];
  const onSelectItemProjectCategory = async (item) => {
    try {
      setcategoryName(item.text);
      setObj({
        ...obj,
        categoryID: item.id,
      });
      setmodalCategory(false);

      LoadProject();
    } catch (error) {
      console.log("error:" + error);
    }
  };

  const LoadProject = async () => {
    try {
      setLoading(true);
      const response = await Api_ProjectList(objRef.current);
      console.log("objRef.current:" + JSON.stringify(objRef.current));
      console.log("LoadProject:" + JSON.stringify(response));
      if (response.data.data == "") {
        setShowemptyList(true);
      } else {
        setProjectList(response.data.data);
        setShowemptyList(false);
      }

      setLoading(false);
    } catch (error) {
      toast.error("error");
    }
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Project" title="Project List" />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Fragment>
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect9">{"Status"}</Label>
                      <Input
                        type="select"
                        name="FilterMod"
                        className="form-control"
                        value={obj.ProjectStatus}
                        onChange={(txt) => {
                          onChangeFilterMod(txt.target.value);
                        }}
                      >
                        <option key={"Notyetstarted"} value={"Notyetstarted"}>
                          Not Yet Started
                        </option>
                        <option key={"Doing"} value={"Doing"}>
                          Doing
                        </option>
                        <option key={"Delivered"} value={"Delivered"}>
                          Delivered
                        </option>
                        <option key={"Done"} value={"Done"}>
                          Done Tasks
                        </option>
                        <option key={"Cancel"} value={"Cancel"}>
                          Cancel
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect9">{"Category"}</Label>

                      {ProjectCategoryList != null && ProjectCategoryList != "" ? (
                        <Input
                          className="form-control"
                          value={categoryName}
                          onClick={() => {
                            setmodalCategory(true);
                          }}
                          readOnly={true}
                        />
                      ) : (
                        <Input
                          className="form-control"
                          value={""}
                          onClick={() => {
                            toast.info("List Is Empty");
                          }}
                          readOnly={true}
                        />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Col>

        {ShowemptyList ? (
          <Col sm="">
            <Alerts attrAlert={{ color: "danger" }}>{"No project found"}</Alerts>
          </Col>
        ) : null}
      </Row>

      <Col sm="12">
        <Card>
          {Loading ? (
            <div className="loader-box3">
              <Spinner attrSpinner={{ className: "loader-15" }} />
            </div>
          ) : ShowemptyList == false ? (
            <AllProjectData allProject={ProjectList} />
          ) : null}
        </Card>
      </Col>
      <Modal isOpen={modalCategory}>
        <ModalHeader>Project Category</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemProjectCategory}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ProjectCategoryList}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setcategoryName("");
              setmodalCategory(false);
              setObj({
                ...obj,
                categoryID: 0,
              });

              LoadProject();
            }}
          >
            Clean Select
          </Button>
          <Button color="light" onClick={() => setmodalCategory(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default ProjectDataListExpert;
