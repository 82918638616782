import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Api_Get, Api_Edit, Api_List } from "../../../api/Admin/Country";
const EditCountry = () => {
  const navigate = useNavigate();
  const { CountryID } = useParams();
  const [obj, setObj] = useState({
    name: "",
  });
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [parentstemp, setParentstemp] = useState([]);
  const [parents, setParents] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await Api_Get(CountryID);
        setObj(response.data);
        console.log(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);
  const onMemberChange = (event) => {
    setObj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const SaveData = async () => {
    try {
      setLoadingBtn(true);
      const data = await Api_Edit(CountryID, obj);
      console.log(data);
      if (data.response) {
        if (data.response.data.IsSuccess == false) {
          toast.error(data.response.data.Message);
        }
      } else if (data.data) {
        if (data.data.isSuccess == true) {
          toast.success("Information edited");
          navigate(`${process.env.PUBLIC_URL}/admin/Countries`);
        }
      } else {
        toast.error("Error");
      }
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Countries" title="Edit Member" />
        <Row>
          {obj.name != "" ? (
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Form className="theme-form">
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <Label>{"Name"}</Label>
                            <InputGroup>
                              <Input
                                name="name"
                                className="form-control"
                                value={obj.name}
                                onChange={onMemberChange}
                                required={true}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Btn
                            attrBtn={{
                              color: "primary",
                              className: "m-r-15",
                              type: "button",
                              onClick: () => SaveData(),
                            }}
                          >
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner
                                  attrSpinner={{ className: "loader-7" }}
                                />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Btn>
                          <Btn
                            attrBtn={{
                              color: "light",
                              type: "button",
                              onClick: () =>
                                navigate(
                                  `${process.env.PUBLIC_URL}/admin/Countries`
                                ),
                            }}
                          >
                            Cancel
                          </Btn>
                        </Col>
                      </Row>
                    </Form>
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          ) : null}
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditCountry;
