import React, { useEffect, useState, Fragment } from "react";
import { Api_MessageList } from "../../../api/Admin/Message";
import { Btn, H6, Image } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
const MessageList = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const LoadData = async () => {
    const response = await Api_MessageList();
    console.log(response.data.data);

    setTableData(response.data.data);
  };
  useEffect(() => {
    LoadData();
  }, []);

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Messages" title="List Message" />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {tableData ? (
                  <div className="table-responsive">
                    <Table>
                      <thead className="bg-primary">
                        <tr>
                          <th scope="col">{"NickName"}</th>
                          <th scope="col">{"Email"}</th>
                          <th scope="col">{"Subject"}</th>
                          <th scope="col">{"Message"}</th>
                          <th scope="col">{"CreateDate"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.nickName}</td>
                            <td>{item.email}</td>
                            <td>{item.subject}</td>
                            <td>{item.message}</td>
                            <td>{item.createDate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MessageList;
