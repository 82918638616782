import React, { Fragment } from "react";
import ChartistGraph from "react-chartist";
import { Col, Card, CardBody, CardHeader } from "reactstrap";
import { SimpleLineChart } from "../../../Constant/index";
import { H5 } from "../../../AbstractElements";
const SimpleLineChartClass = ({ chartData }) => {
  return (
    <Fragment>
      <Col xl="12" md="12" sm="12" className="box-col-12">
        <Card>
          <CardHeader className="pb-0">
            <H5>Task Chart</H5>
          </CardHeader>
          <CardBody>
            <ChartistGraph data={chartData.chart10Data} options={chartData.chart10Options} type={"Line"} className="ct-1 flot-chart-container" />
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default SimpleLineChartClass;
