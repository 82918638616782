import ProjectDataListPM from "./ProjectDataPM/ProjectDataListPM";
import ProjectDataListExpert from "./ProjectDataExpert/ProjectDataListExpert";
import React, { Fragment, useContext } from "react";
import { Container, Row } from "reactstrap";
import MemberTypeContext from "../../../_helper/MemberType";
const ProjectListContain = (props) => {
  const { typeName } = useContext(MemberTypeContext);
  return (
    <Fragment>
      <Container fluid={true}>
        <Row className=" project-cards">{typeName == "Expert" ? <ProjectDataListExpert /> : <ProjectDataListPM />}</Row>
      </Container>
    </Fragment>
  );
};
export default ProjectListContain;
