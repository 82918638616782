import React, { Fragment, useEffect } from "react";
import { Col, Row } from "reactstrap";
import ChatMessage from "./ChatMessage";
import ChatHeader from "./ChatHeader";
import SendChat from "./SendChat";
import { Api_Add } from "../../../api/Admin/Chat";
import { Api_Get } from "../../../api/Admin/Chat";
import { Api_ListReferProject } from "../../../api/Admin/Project";
import { Link, useNavigate, useParams } from "react-router-dom";
import useState from "react-usestateref";

const Chatting = ({ typeName, setobjBreadcrumbsTitle }) => {
  const navigate = useNavigate();

  const { ProjectID, TaskID, ChatBy, MainID } = useParams();
  const [ChatList, setChatList] = useState([]);
  const [TaskList, setTaskList] = useState([]);

  useEffect(() => {
    if (TaskID && MainID) {
      setObj({
        ...obj,
        taskID: TaskID,
        projectID: ProjectID,
        chatBy: ChatBy,
        mainID: MainID,
      });
    } else if (TaskID && !MainID) {
      setObj({
        ...obj,
        taskID: TaskID,
        projectID: ProjectID,
        chatBy: ChatBy,
        mainID: 0,
      });
    } else {
      setObj({
        ...obj,
        taskID: 0,
        projectID: ProjectID,
        chatBy: ChatBy,
        mainID: 0,
      });
    }
    const newobj = objRef.current;
    fetchData(newobj);
    setobjBreadcrumbsTitle(ChatBy);
  }, []);

  const [obj, setObj, objRef] = useState({
    message: "",
    projectID: 0,
    taskID: 0,
    chatBy: "Expert",
    mainID: 0,
  });
  const handleMessagePress = async (e) => {
    if (e.key === "Enter" || e === "send") {
      if (obj.message.length > 0) {
        console.log(JSON.stringify(obj));
        const response = await Api_Add(obj);

        if (response) {
          console.log("a:");
          if (response.data.isSuccess == false) {
          } else if (response.data.isSuccess == true) {
            console.log("response.data.isSuccess:" + response.data.isSuccess);
            setObj({
              ...obj,
              message: "",
            });
            const newobj = objRef.current;
            fetchData(newobj);
          } else {
            console.log("c");
          }
        } else if (response.data) {
          console.log("b");
        } else {
          console.log(" err response.data.isSuccess:" + response.data.isSuccess);
        }
      }
    }
  };
  const fetchData = async (newobj) => {
    try {
      console.log("val 2 :" + JSON.stringify(newobj));
      const response = await Api_Get(newobj.projectID, newobj.taskID, newobj.chatBy);

      setChatList(response.data.data);

      const response2 = await Api_ListReferProject(ProjectID, "0");
      setTaskList(response2.data.data);
    } catch (err) {
      console.log("err:" + err);
    }
  };
  const onChangeTask = (val) => {
    setObj({
      ...obj,
      taskID: val,
    });

    const newobj = objRef.current;
    fetchData(newobj);
  };
  const onChangeChatBy = (val) => {
    setObj({
      ...obj,
      chatBy: val,
    });
    const newobj = objRef.current;
    fetchData(newobj);

    setobjBreadcrumbsTitle(val);
  };
  return (
    <Fragment>
      <Row className="chat-box">
        <Col className="chat-right-aside">
          <div className="chat">
            <ChatHeader
              typeName={typeName}
              obj={obj}
              TaskList={TaskList}
              onChangeTask={onChangeTask}
              onChangeChatBy={onChangeChatBy}
              setObj={setObj}
            />
            <ChatMessage ChatList={ChatList} obj={obj} />
            <SendChat handleMessagePress={handleMessagePress} obj={obj} setObj={setObj} />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Chatting;
