import React, { Fragment } from "react";
import { H4, H6, P, UL } from "../../../AbstractElements";

const FormHeader = (props) => {
  const { selected = "" } = props;
  return (
    <Fragment>
      <div>
        <H6>{"Welcome back! Log in to your account."}</H6>
      </div>
    </Fragment>
  );
};
export default FormHeader;
