import React, { Fragment, useState, useEffect } from "react";
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { Btn, H5, UL, P } from "../../../AbstractElements";
import { Password, SignIn } from "../../../Constant";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormPassword from "./FormPassword";
import { getToken } from "../../../api/Auth/GetToken";
import { useContext } from "react";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { ToastContainer, toast } from "react-toastify";
const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { setTypeName } = useContext(MemberTypeContext);

  const login = async () => {
    setDisableBtn(true);
    setLoading(true);
    if (email != "" && password != "") {
      const tokenData = await getToken(email, password);
      console.log("tokenData:" + tokenData);
      if (tokenData !== "404") {
        toast.error(tokenData);
        if (tokenData.status == "200") {
          setTypeName(tokenData.data.memberType);
          await localStorage.setItem("Name", tokenData.data.dispaly_name);
          await localStorage.setItem("token", tokenData.data.access_token);
          await localStorage.setItem("login", true);

          toast.success("You have successfully login");
          setTimeout(() => {
            navigate(`${process.env.PUBLIC_URL}/dashboard`, { replace: true });
          }, 2000);
        } else {
          toast.error("email or password is incorrect");
          console.log("c");
        }
      } else {
        toast.error("email or password is incorrect");
        console.log("a");
      }
    } else {
      toast.warning("Please fill paramter");
      console.log("b");
    }
    setDisableBtn(false);
    setLoading(false);
  };

  return (
    <Fragment>
      <Form className="theme-form login-form">
        <FormHeader selected={selected} />
        <FormGroup>
          <Label>User Name</Label>

          <InputGroup>
            <InputGroupText>
              <i className="icon-email"></i>
            </InputGroupText>
            <Input
              className="form-control"
              type="text"
              required=""
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={email}
              placeholder="User Name"
            />
          </InputGroup>
          <p style={email ? { color: "red", display: "none" } : { color: "red" }}>Please insert correct email format</p>
        </FormGroup>
        <FormGroup style={email ? {} : { marginTop: "-20px" }}>
          <Label>{Password}</Label>
          <InputGroup>
            <InputGroupText>
              <i className="icon-lock"></i>
            </InputGroupText>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              defaultValue={password}
              required=""
              placeholder="Password"
            />
            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </InputGroup>
        </FormGroup>
        <FormPassword />
        <FormGroup>
          <Btn
            attrBtn={{
              color: "primary",
              className: "btn-block",
              disabled: disableBtn,
              onClick: (e) => login(),
            }}
          >
            {loading ? "LOADING..." : SignIn}
          </Btn>
        </FormGroup>
        <P>
          You do not have an account?
          <Link to={`${process.env.PUBLIC_URL}/pages/authentication/register`} className="ms-2">
            Sign Up
          </Link>
        </P>
      </Form>
      <ToastContainer />
    </Fragment>
  );
};

export default LoginTab;
