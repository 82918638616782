import axios from "axios";
import React from "react";

import { URL, Version } from "../URL";

const reagisterUser = async (UserData) => {
  try {
    const response = await axios.post(`${URL}${Version}/Users`, UserData);
    return response;
  } catch (er) {
    console.log("er:" + er);
    return "Error executing the request";
  }
};

export default reagisterUser;
