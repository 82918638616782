import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import MainList from "./MainList";

const CreateListContain = (props) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <MainList />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CreateListContain;
