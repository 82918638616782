import axios from "axios";
import { URL, Version } from "../URL";
const Api_Add = async (data) => {
  const token = await localStorage.getItem("token");
  try {
    const response = await axios.post(`${URL}${Version}/Chat`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
const Api_Get = async (ProjectID, TaskID, chatBy) => {
  const token = await localStorage.getItem("token");

  try {
    const response = await axios.get(`${URL}${Version}/Chat/Get/${ProjectID}/${TaskID}/${chatBy}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (er) {
    return er;
  }
};
export { Api_Add, Api_Get };
