import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Lock, Mail, User } from "react-feather";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Btn, H4, H5, H6, LI, P, UL } from "../../../AbstractElements";
import { CreateAccount, EmailAddress, Password, PrivacyPolicy, SignIn, YourName } from "../../../Constant";
import reagisterUser from "../../../api/Auth/Register";
import { Api_Get } from "../../../api/Admin/StaticText";
import { getToken } from "../../../api/Auth/GetToken";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import MemberTypeContext from "../../../_helper/MemberType/index";

const RegisterFrom = (props) => {
  const { typeName, setTypeName } = useContext(MemberTypeContext);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [txtModal, settxtModal] = useState("");

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userName: "",
  });
  const LoadData = async () => {
    const response = await Api_Get(1);

    settxtModal(response.data.data.text);
  };
  useEffect(() => {
    LoadData();
  }, []);
  const [disableBtn, setDisableBtn] = useState(true);

  const [showPass, setShowPass] = useState(true);

  const [loading, setLoading] = useState(false);

  const registerUser = async () => {
    setTypeName("Recruiter");
    setDisableBtn(true);
    setLoading(true);
    if (userData.password !== "" && userData.email !== "" && userData.fullName !== "") {
      const data = await reagisterUser(userData);
      console.log("response 1:" + JSON.stringify(data));

      if (data.data) {
        if (data.data.isSuccess === false) {
          toast.error(data.data.message);
          setDisableBtn(false);
          setLoading(false);
        } else if (data.data.isSuccess === true) {
          setDisableBtn(false);
          setLoading(false);
          toast.success("Your registration was successful");
          const tokenData = await getToken(userData.userName, userData.password);
          console.log("tokenData:" + JSON.stringify(tokenData));
          await localStorage.setItem("Name", tokenData.data.dispaly_name);
          await localStorage.setItem("token", tokenData.data.access_token);
          setTypeName("Recruiter");
          setTimeout(() => {
            navigate(`${process.env.PUBLIC_URL}/dashboard`, {
              replace: true,
            });
          }, 2000);
        } else {
          toast.error(data);
          setDisableBtn(false);
          setLoading(false);
        }
      } else {
        toast.error(data);
      }
    } else {
      toast.warning("Please fill paramter");
    }
    setDisableBtn(false);
    setLoading(false);
  };
  const toggle = () => setModal(!modal);
  return (
    <Fragment>
      <Form className="theme-form login-form">
        <div className="login-header text-center">
          <H4>Create your account</H4>
          <H6>Enter your personal details to create account</H6>
        </div>
        <div className="login-social-title">
          <H5>Sign in with Email</H5>
        </div>
        <FormGroup className="form-group">
          <Label>ّّFirst Name</Label>
          <div className="small-group">
            <InputGroup>
              <span className="input-group-text">
                <User />
              </span>
              <Input
                className="form-control"
                type="text"
                required=""
                placeholder="Fist Name"
                onChange={(txt) => {
                  setUserData({ ...userData, firstName: txt.target.value });
                }}
              />
            </InputGroup>
          </div>
        </FormGroup>
        <FormGroup className="form-group">
          <Label>Last Name</Label>
          <div className="small-group">
            <InputGroup>
              <span className="input-group-text">
                <User />
              </span>
              <Input
                className="form-control"
                type="text"
                required=""
                placeholder="Last Name"
                onChange={(txt) => {
                  setUserData({ ...userData, lastName: txt.target.value });
                }}
              />
            </InputGroup>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>{EmailAddress}</Label>
          <InputGroup>
            <span className="input-group-text">
              <Mail />
            </span>
            <Input
              className="form-control"
              type="email"
              required=""
              placeholder="Test@gmail.com"
              onChange={(txt) => {
                if (/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(txt.target.value))
                  setUserData({
                    ...userData,
                    email: txt.target.value,
                    userName: txt.target.value,
                  });
                else setUserData({ ...userData, email: "", userName: "" });
              }}
            />
          </InputGroup>
          <p style={userData.email ? { color: "red", marginTop: "10px", display: "none" } : { color: "red", marginTop: "10px" }}>
            Please insert correct email format
          </p>
        </FormGroup>
        <FormGroup>
          <Label>{Password}</Label>
          <InputGroup>
            <span className="input-group-text">
              <Lock />
            </span>
            <Input
              className="form-control"
              type={showPass ? "password" : "text"}
              name="login[password]"
              required=""
              placeholder="*********"
              onChange={(txt) => {
                setUserData({ ...userData, password: txt.target.value });
              }}
            />
            <div
              className="show-hide"
              onClick={() => {
                setShowPass(!showPass);
              }}
            >
              <span className="show"></span>
            </div>
          </InputGroup>
        </FormGroup>
        <FormGroup className="form-group">
          <div className="checkbox">
            <Input id="checkbox1" type="checkbox" onClick={() => setDisableBtn(!disableBtn)} />
            <Label className="text-muted" for="checkbox1">
              Agree with <span onClick={toggle}>{PrivacyPolicy}</span>
            </Label>
          </div>
        </FormGroup>
        <FormGroup>
          <Btn
            attrBtn={{
              disabled: disableBtn,
              className: "btn-block",
              color: "primary",
              onClick: () => {
                registerUser();
              },
            }}
          >
            {loading ? "Loading..." : CreateAccount}
          </Btn>
        </FormGroup>
        <P>
          Already have an account?
          <Link to={`${process.env.PUBLIC_URL}/pages/authentication/login`} className="ms-2">
            Sign In
          </Link>
        </P>
      </Form>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: txtModal }}></div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <ToastContainer />
    </Fragment>
  );
};

export default RegisterFrom;
