import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";
import { Api_Add, Api_List } from "../../../api/Admin/Country";

const NewCountry = () => {
  const navigate = useNavigate();
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [obj, setObj] = useState({
    name: "",
  });

  const onMemberChange = (event) => {
    setObj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const SaveData = async () => {
    setLoadingBtn(true);
    const data = await Api_Add(obj);
    setLoadingBtn(false);
    console.log(data);
    if (data.response) {
      if (data.response.data.IsSuccess == false) {
        toast.error(data.response.data.Message);
      }
    } else if (data.data) {
      if (data.data.isSuccess == true) {
        toast.success("A new Country was created");
        navigate(`${process.env.PUBLIC_URL}/admin/Countries`);
      }
    } else {
      toast.error("Error");
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Countries" title="New" />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Fragment>
                  <Form className="theme-form">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"Name"}</Label>
                          <InputGroup>
                            <Input
                              name="name"
                              className="form-control"
                              value={obj.name}
                              onChange={onMemberChange}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Btn
                          attrBtn={{
                            color: "success",
                            className: "m-r-15",
                            type: "button",
                            disabled: LoadingBtn,
                            onClick: () => SaveData(),
                          }}
                        >
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner
                                attrSpinner={{ className: "loader-7" }}
                              />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Btn>
                      </Col>
                    </Row>
                  </Form>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewCountry;
