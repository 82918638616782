import React, { useContext, useState } from "react";
import { Col, Input, InputGroup, Row } from "reactstrap";
import EmojiPicker from "emoji-picker-react";
import { Send } from "../../../Constant/index";

import { Btn, Image } from "../../../AbstractElements";

const SendChat = ({ handleMessagePress, obj, setObj }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const toggleEmojiPicker = () => {
    console.log("show");
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emoji) => {
    console.log(emoji.emoji);
    const text = `${obj.message}${emoji.emoji}`;
    setShowEmojiPicker(false);
    setObj({
      ...obj,
      message: text,
    });
  };
  const handleMessageChange = (message) => {
    setObj({
      ...obj,
      message: message,
    });
  };

  return (
    <div className="chat-message clearfix">
      <Row>
        <div>{showEmojiPicker ? <EmojiPicker set="apple" emojiSize={30} onEmojiClick={addEmoji} /> : null}</div>
        <Col xl="12" className="d-flex">
          <div className="smiley-box bg-primary">
            <div className="picker" onClick={() => toggleEmojiPicker()}>
              <Image attrImage={{ src: `${require("../../../assets/images/smiley.png")}`, alt: "" }} />
            </div>
          </div>
          <InputGroup className="text-box">
            <Input
              type="text"
              className="form-control input-txt-bx"
              placeholder="Type a message......"
              value={obj.message}
              onKeyPress={(e) => handleMessagePress(e)}
              onChange={(e) => handleMessageChange(e.target.value)}
            />
            <Btn attrBtn={{ color: "primary", onClick: () => handleMessagePress("send") }}>{Send}</Btn>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

export default SendChat;
