import React, { Fragment, useContext, useEffect, useState } from "react";
import ChatAppContext from "../../../_helper/chat-app/index";
import { Image, LI, UL, h5 } from "../../../AbstractElements";
import start_conversion from "../../../assets/images/start-conversion.jpg";

const ChatMessage = ({ ChatList, obj }) => {
  return (
    <Fragment>
      {ChatList ? (
        <div className="chat-history chat-msg-box custom-scrollbar">
          {ChatList != null && ChatList != "" ? (
            ChatList.map((item, index) => {
              return (
                <UL attrUL={{ className: "simple-list" }} key={index}>
                  <LI attrLI={{ className: "clearfix" }}>
                    <div className={`message my-message ${item.sender !== true ? "" : "pull-right other-message"}`}>
                      <Image
                        attrImage={{
                          src: `${item.img}`,
                          className: `rounded-circle ${item.sender !== true ? "float-start " : "float-end "}chat-user-img img-30`,
                          alt: "",
                        }}
                      />
                      <div className="message-data text-start">
                        <h5>{item.userMemberType}:</h5>

                        {item.projectCategoryTaskTitle != null && obj.taskID == 0 ? <h6>Task:{item.projectCategoryTaskTitle}</h6> : null}
                        <span className="message-data-time">
                          {item.dataAdd.toString().substring(0, 10) + " " + item.dataAdd.toString().substring(11, 16)}
                        </span>
                      </div>
                      {item.message}
                    </div>
                  </LI>
                </UL>
              );
            })
          ) : (
            <Image
              attrImage={{
                className: "img-fluid",
                src: `${start_conversion}`,
                alt: "start conversion ",
              }}
            />
          )}
        </div>
      ) : (
        <div className="loading"></div>
      )}
    </Fragment>
  );
};
export default ChatMessage;
