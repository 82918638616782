import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Api_ChangePassword } from "../../../api/Admin/User";
const ChangePassword = () => {
  const navigate = useNavigate();
  const [MemberObj, setMemberObj] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Repeatpassword, setRepeatpassword] = useState("");
  const onMemberChange = (event) => {
    setMemberObj({
      ...MemberObj,
      [event.target.name]: event.target.value,
    });
  };
  const SaveData = async () => {
    try {
      setLoadingBtn(true);
      const { data: response, status } = await Api_ChangePassword(MemberObj);
      console.log(response);
      if (status == 200 && response.isSuccess == true) {
        toast.success("Information edited");
        // navigate(`${process.env.PUBLIC_URL}`);
        setMemberObj({
          currentPassword: "",
          newPassword: "",
        });
      } else if (status == 200 && response.isSuccess == false) {
        toast.error(response.message);
      } else {
        toast.error("Error");
      }
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Portal" title="Change Password" />
        <Row>
          <Col sm="12" style={{ marginTop: "45px" }}>
            <Card>
              <CardBody>
                <Fragment>
                  <Form className="theme-form">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"Current Password"}</Label>
                          <InputGroup>
                            <Input
                              name="currentPassword"
                              className="form-control"
                              value={MemberObj.currentPassword}
                              onChange={onMemberChange}
                              required={true}
                              type="password"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"New Password"}</Label>
                          <InputGroup>
                            <Input
                              name="newPassword"
                              className="form-control"
                              value={MemberObj.newPassword}
                              onChange={onMemberChange}
                              required={true}
                              type="password"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>{"Repeat New password"}</Label>
                          <InputGroup>
                            <Input
                              name="password"
                              className="form-control"
                              value={Repeatpassword}
                              onChange={(val) => {
                                setRepeatpassword(val.target.value);
                              }}
                              required={true}
                              type="password"
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Btn
                          onClick={() => alert("ddd")}
                          attrBtn={{
                            color: "primary",
                            className: "m-r-15",
                            type: "button",
                            onClick: () => SaveData(),
                          }}
                        >
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner
                                attrSpinner={{ className: "loader-7" }}
                              />
                            </div>
                          ) : (
                            "Save"
                          )}
                        </Btn>
                        <Btn
                          attrBtn={{
                            color: "light",
                            type: "button",
                            onClick: () =>
                              navigate(`${process.env.PUBLIC_URL}`),
                          }}
                        >
                          Cancel
                        </Btn>
                      </Col>
                    </Row>
                  </Form>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ChangePassword;
