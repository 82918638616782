import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";
import { Api_CategoryName } from "../../../api/Admin/ProjectCategory";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Api_Get, Api_Edit } from "../../../api/Admin/CategoryLevel";
const EditProjectCategoryLevel = () => {
  const [CategoryName, setCategoryName] = useState("");
  const navigate = useNavigate();
  const { ProjectCategoryID, ProjectCategoryLevelID } = useParams();

  const [obj, setObj] = useState({
    title: "",
    price: 0,
    categoryId: ProjectCategoryID,
  });
  const [LoadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response1 } = await Api_CategoryName(ProjectCategoryID);
        setCategoryName(response1.data.title);

        const { data: response } = await Api_Get(ProjectCategoryLevelID);
        console.log("response:" + JSON.stringify(response.data));
        setObj(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);
  const onMemberChange = (event) => {
    setObj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const SaveData = async () => {
    try {
      setLoadingBtn(true);
      const data = await Api_Edit(ProjectCategoryLevelID, obj);
      console.log(data);
      if (data.response) {
        if (data.response.data.IsSuccess == false) {
          toast.error(data.response.data.Message);
        }
      } else if (data.data) {
        if (data.data.isSuccess == true) {
          toast.success("Information edited");
          navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategoryLevel/${ProjectCategoryID}`);
        }
      } else {
        toast.error("Error");
      }
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Edit Level" title={CategoryName} />
        <Row>
          {obj.title != "" ? (
            <Col sm="12">
              <Card>
                <CardBody>
                  <Fragment>
                    <Form className="theme-form">
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Name"}</Label>
                            <InputGroup>
                              <Input name="title" className="form-control" value={obj.title} onChange={onMemberChange} required={true} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{"Price"}</Label>
                            <InputGroup>
                              <Input name="price" className="form-control" value={obj.price} onChange={onMemberChange} required={true} />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Btn
                            attrBtn={{
                              color: "primary",
                              className: "m-r-15",
                              type: "button",
                              onClick: () => SaveData(),
                            }}
                          >
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Btn>
                          <Btn
                            attrBtn={{
                              color: "light",
                              type: "button",
                              onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategoryLevel/${ProjectCategoryID}`),
                            }}
                          >
                            Cancel
                          </Btn>
                        </Col>
                      </Row>
                    </Form>
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          ) : null}
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditProjectCategoryLevel;
