import React, { useEffect, useState, Fragment, useMemo, useRef } from "react";
import { Api_List, Api_Delete } from "../../../api/Admin/ProjectCategory";
import { Btn, H6, Image, H5 } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { CheckCircle, Info, PlusCircle, Target } from "react-feather";
import MaterialReactTable from "material-react-table";
import DeniReactTreeView from "deni-react-treeview";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";

const ListProjectCategory = () => {
  const navigate = useNavigate();
  const [SelectetID, setSelectetID] = useState(0);
  const [modal, setModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useRef(null);
  const LoadData = async () => {
    const response = await Api_List();
    console.log(JSON.stringify(response));
    setTableData(response.data.data);
  };
  useEffect(() => {
    LoadData();
  }, []);
  const DeleteMember = async (SelectetID) => {
    const { data: response, status } = await Api_Delete(SelectetID);
    if (status == 200 && response.isSuccess == true) {
      toast.success("Category  Deleted");
      removeFromList(SelectetID);
    } else if (status == 200 && response.isSuccess == false) {
      toast.error(response.Message);
    } else {
      toast.error("Error");
    }
    setModal(false);
  };
  const removeFromList = async (SelectetID) => {
    try {
      //console.log('tableData:'+JSON.stringify(tableData))
      const updatedContact = tableData.filter((c) => c.Id !== SelectetID);
      setTableData(updatedContact);
    } catch (err) {
      toast.error("Error");
    }
  };
  const toggle = () => setModal(!modal);
  const columns = useMemo(
    () => [
      {
        accessorKey: "Title",
        header: "Title",
      },

      {
        header: "Active Order",
        Cell: ({ cell }) =>
          cell.row.original.ActiveOrder ? <span className="txt-success">Active</span> : <span className="txt-danger">Inactive</span>,
      },
      {
        header: "Level",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategoryLevel/${cell.row.id}`)}
              >
                Level
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Task",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategoryTask/${cell.row.id}`)}
              >
                Task
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Action",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-success mr-5 btn-xs"
                onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategory/edit/${cell.row.id}`)}
              >
                Edit
              </Button>
              <Button
                className="btn btn-danger btn-xs"
                onClick={() => {
                  setSelectetID(cell.row.id);
                  toggle();
                }}
              >
                Delete
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Post Category" title="List" />
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col md="12">
                  <Link className="btn btn-primary" style={{ color: "white" }} to={`${process.env.PUBLIC_URL}/admin/ProjectCategory/Add`}>
                    <PlusCircle />
                    Create New Category
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <CardBody>
                {tableData != null ? (
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody>
                          <MaterialReactTable
                            enableStickyHeader
                            // muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
                            muiTablePaperProps={{
                              elevation: 0,
                              sx: {
                                color: "#fff",
                                borderRadius: "0",
                                border: "0",
                                "& tbody tr:nth-of-type(odd)": {
                                  backgroundColor: "#f5f5f5",
                                },
                                "& thead tr": {
                                  backgroundColor: "#16449D",
                                  color: "#fff",
                                },
                              },
                            }}
                            muiTableHeadCellProps={{
                              sx: (theme) => ({
                                color: "#fff",
                              }),
                            }}
                            muiTableHeadCellDragHandleProps={{
                              sx: (theme) => ({
                                color: "#fff",
                              }),
                            }}
                            muiTableHeadCellColumnActionsButtonProps={{
                              sx: (theme) => ({
                                color: "#fff",
                              }),
                            }}
                            muiTableHeadCellFilterTextFieldProps={{
                              sx: (theme) => ({
                                color: "#fff",
                              }),
                            }}
                            getRowId={(originalRow) => originalRow.Id}
                            columns={columns}
                            data={tableData}
                            enableColumnOrdering
                            enableExpanding
                            enablePagination={false}
                            onRowSelectionChange={setRowSelection}
                            state={{ rowSelection }}
                            tableInstanceRef={tableInstanceRef}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => DeleteMember(SelectetID)}>
            Yes
          </Button>
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ListProjectCategory;
