import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Breadcrumbs, H5, H6, Spinner, Btn } from "../../../AbstractElements";
import { Api_Add, Api_Edit, Api_Get, Api_List } from "../../../api/Admin/ProjectCategoryTask";
import { Api_CategoryName } from "../../../api/Admin/ProjectCategory";
import { Api_GetLevelsTaskCheked } from "../../../api/Admin/CategoryLevel";
const EditProjectCategoryTask = () => {
  const { ProjectCategoryID, ProjectCategoryTaskID } = useParams();
  const [CategoryName, setCategoryName] = useState("");
  const [LevelsList, setLevelsList] = useState([]);
  const navigate = useNavigate();
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [modalLEvel, setmodalLevel] = useState(false);
  const [obj, setObj] = useState({
    title: "",
    categoryId: ProjectCategoryID,
    code: "",
    ListTask: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response1 } = await Api_CategoryName(ProjectCategoryID);
        setCategoryName(response1.data.title);

        const { data: response2 } = await Api_GetLevelsTaskCheked(ProjectCategoryTaskID, ProjectCategoryID);
        setLevelsList(response2.data);

        const { data: response } = await Api_Get(ProjectCategoryTaskID);
        setObj({
          title: response.data.title,
          categoryId: response.data.categoryId,
          code: response.data.code,
          ListTask: response2.data,
        });
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, []);

  const onMemberChange = (event) => {
    setObj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const SaveData = async () => {
    setLoadingBtn(true);
    console.log("ProjectCategoryTaskID:" + ProjectCategoryTaskID);
    console.log("obj:" + obj);
    const data = await Api_Edit(ProjectCategoryTaskID, obj);

    setLoadingBtn(false);
    console.log(data);
    if (data.response) {
      if (data.response.data.IsSuccess == false) {
        toast.error(data.response.data.Message);
      }
    } else if (data.data) {
      if (data.data.isSuccess == true) {
        toast.success("Information edited");
        navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategoryTask/${ProjectCategoryID}`);
      }
    } else {
      toast.error("Error");
    }
  };

  const onChangeLevel = async (id, name, val) => {
    const AllData = [...LevelsList];
    const DataIndex = AllData.findIndex((p) => p.id == id);
    const TempData = AllData[DataIndex];
    if (name == "checked") {
      TempData.checked = val;
    }
    AllData[DataIndex] = TempData;
    setLevelsList(AllData);
    setObj({
      ...obj,
      ListTask: AllData,
    });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="New Task" title={CategoryName} />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Fragment>
                  <Form className="theme-form">
                    <Row>
                      <Col sm="10">
                        <FormGroup>
                          <Label>{"Name"}</Label>
                          <InputGroup>
                            <Input name="title" className="form-control" value={obj.title} onChange={onMemberChange} required />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label>{"Code"}</Label>
                          <InputGroup>
                            <Input name="code" className="form-control" value={obj.code} onChange={onMemberChange} required />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <thead className="bg-primary">
                                <tr>
                                  <th scope="col">Level</th>
                                  <th scope="col">Access</th>
                                </tr>
                              </thead>
                              <tbody>
                                {LevelsList.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <th scope="row">{item.title}</th>
                                      <td>
                                        <FormGroup className="form-group">
                                          <div className="checkbox">
                                            <Input id={item.id + "checkboxSee"} type="checkbox" defaultChecked={item.checked} onClick={() => onChangeLevel(item.id, "checked", !item.checked)} />
                                            <Label className="text-muted" for={item.id + "checkboxSee"}></Label>
                                          </div>
                                        </FormGroup>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Btn
                          attrBtn={{
                            color: "success",
                            className: "m-r-15",
                            type: "button",
                            disabled: LoadingBtn,
                            onClick: () => SaveData(),
                          }}
                        >
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Save"
                          )}
                        </Btn>
                        <Btn
                          attrBtn={{
                            color: "light",
                            type: "button",
                            onClick: () => navigate(`${process.env.PUBLIC_URL}/admin/ProjectCategoryTask/${ProjectCategoryID}`),
                          }}
                        >
                          Cancel
                        </Btn>
                      </Col>
                    </Row>
                  </Form>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditProjectCategoryTask;
